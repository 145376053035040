// import MYZH from './my/zh-Hans.json';

// export type ZhKeys =
//   | keyof typeof MYZH;

// export type ZhType = {
//   [k in ZhKeys]: string;
// };

// const en: ZhType = require(`./${BIZ_COUNTRY}/zh-Hans.json`);
// export default en;
import zh from './my/zh-Hans.json';

export default zh;
