import { UserInfoService } from './userInfo';
import { GeneralService } from './general';
import { HomeService } from './home/my';
import { RepaymentService } from './repayment/my';
import { PercentageService } from './percentage/my';
import { UpcomingService } from './upcoming/my';
import { TransactionService } from './transaction/my';
import { AdvanceService } from './advance/my';
import { OrderService } from './order/my';

export default {
  userInfo: new UserInfoService(),
  general: new GeneralService(),
  home: new HomeService(),
  repayment: new RepaymentService(),
  percentage: new PercentageService(),
  upcomingStore: new UpcomingService(),
  transaction: new TransactionService(),
  advance: new AdvanceService(),
  order: new OrderService(),
};
