import IDCfg from './id';
import MYCfg from './my';
import BRCfg from './br';
import PHCfg from './ph';
import THCfg from './th';

export type CfgType = typeof IDCfg & typeof MYCfg & typeof BRCfg & typeof PHCfg & typeof THCfg;

// const configMap = {
//   id: IDCfg,
//   my: MYCfg,
// };

// let config = configMap[BIZ_COUNTRY as keyof typeof configMap] as CfgType;
const config: CfgType = require(`./${BIZ_COUNTRY}`).default;

export default config;
