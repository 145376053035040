import { Expose } from 'class-transformer';
import { defaultStartDate, defaultEndDate } from '@/stores/transaction/const';

export class AdvanceList {
  /**
   * @deprecated in refactor
   */
  @Expose()
  disbursement_date!: number;

  /**
   * 实际放款成功的时间
   */
  @Expose()
  actual_disburse_time!: number;

  @Expose()
  group_id!: number;

  /**
   * @deprecated in refactor
   */
  @Expose()
  disbursement_amount!: number;

  /**
   * 放款组的实际放款金额，扣掉了服务费的
   */
  @Expose()
  actual_disburse_amount!: number;

  /**
   * 服务费
   */
  @Expose()
  service_charge_amount!: number;

  @Expose()
  principal_outstanding_amount!: number;

  @Expose()
  due_date!: number;

  @Expose()
  repayment_status!: number;

  @Expose()
  disburse_percentage!: number;

  /**
   * 放款组的放款金额，没有扣掉服务费的
   */
  @Expose()
  principal_amount!: number;

  /**
   * UI的last transaction date的时间戳，单位：秒
   */
  @Expose()
  update_time!: number;
}

/**
 *  https://mock.test.shopeekredit.co.id/project/121/interface/api/1062
 */
export class AdvanceModel {
  @Expose()
  list: AdvanceList[] = [];

  @Expose()
  dataSource: any[] = [];

  @Expose()
  has_more: boolean = true;

  @Expose()
  total: number = 0;

  /**
   * @deprecated in refactor
   * 选中时间段内放款金额总和，扣掉了服务费的；当请求的页数不是第一页时，返回-1
   */
  @Expose()
  total_disbursement_amount: number = 0;

  /**
   * 选中时间段内放款金额总和，扣掉了服务费的；当请求的页数不是第一页时，返回-1
   */
  @Expose()
  actual_disburse_amount: number = 0;

  @Expose()
  page_index: number = 0;

  @Expose()
  page_size: number = 15;

  @Expose()
  start_time: Date = defaultStartDate;

  @Expose()
  end_time: Date = defaultEndDate;

  @Expose()
  loading: boolean = false;

  @Expose()
  whateverSendrequest: boolean = false;
}
export class AdvanceInfoModel {
  /**
   * Disbursement Amount 的 tooltip 里面的 eligible amount ：sum(entity.mortgage_amount)
   */
  @Expose()
  total_mortgage_amount: number = 0;

  /**
   *
   */
  @Expose()
  user_id: number = 0;

  /**
   * @deprecated in refactor
   * 放款时是否动态调整了放款比例
   */
  @Expose()
  is_system_percentage: boolean = false;

  /**
   * @deprecated in refactor
   * 该group的放款本金
   */
  @Expose()
  disbursement_amount: number = 0;

  /**
   * 该group的实际放款金额，扣掉了服务费的
   */
  @Expose()
  actual_disburse_amount: number = 0;

  /**
   * 未扣除服务费的放款金额
   */
  @Expose()
  principal_amount: number = 0;

  /**
   * 该group的还款日
   */
  @Expose()
  repay_time: number = 0;

  /**
   * @deprecated in refactor
   * 该group的逾期时间
   */
  @Expose()
  due_date: number = 0;

  /**
   * 利息的日利率：repayment amount 的 tooltip 里面的 daily fee rate
   */
  @Expose()
  daily_interest_rate: number = 0;

  /**
   * 未还本金
   */
  @Expose()
  principal_outstanding_amount: number = 0;

  /**
   * 实际的放款比例：FE-App 的 Loan Percentage，FE-Web 的 Disbursement Percentage
   */
  @Expose()
  disburse_percentage: number = 0;

  /**
   * 放款组group的还款状态：0-All（全部已成功放款的group）；1-Repaying；2-Fully Repaid。后端只会返回 1 或 2 。
   */
  @Expose()
  repayment_status: number = 0;

  /**
   * groupExtInfo.AvailableBalance
   */
  @Expose()
  available_limit: number = 0;

  /**
   * @deprecated in refactor
   * 该group的实际的成功放款时间，FE-App：disbursed date；FE-Web：disbursement date
   */
  @Expose()
  disbursement_date: number = 0;

  /**
   * 该group的实际的成功放款时间，FE-App：disbursed date；FE-Web：disbursement date
   */
  @Expose()
  actual_disburse_time: number = 0;

  /**
   *
   */
  @Expose()
  group_id: number = 0;

  /**
   * @deprecated in refactor
   * 所有应还金额：应还本金+应还利息（repayment amount）
   */
  @Expose()
  total_amount_receivable: number = 0;

  /**
   * 所有应还金额：应还本金+应还利息（repayment amount）
   */
  @Expose()
  total_payable_amount: number = 0;

  /**
   * 所有未还金额：未还本金+未还利息（outstanding amount）
   */
  @Expose()
  total_outstanding_amount: number = 0;

  /**
   * @deprecated in refactor
   * 所有的应还利息：tooltip 里面的 total fee
   */
  @Expose()
  total_interest_receivable: number = 0;

  /**
   * 所有的应还利息：tooltip 里面的 total fee
   */
  @Expose()
  total_interest: number = 0;

  /**
   * repayment amount 的 tooltip 里面的 repayment period，单位是天，比如：14
   */
  @Expose()
  repayment_period_in_day: number = 0;

  /**
   * @deprecated in refactor
   * BR新增
   */
  @Expose()
  anticipate_amount: number = 0;

  /**
   * @deprecated in refactor
   * BR新增
   */
  @Expose()
  service_charge: number = 0;

  /**
   * 该group的服务费
   */
  @Expose()
  service_charge_amount: number = 0;

  /**
   * 该group的服务费费率
   */
  @Expose()
  service_charge_rate: number = 0;

  /**
   * 该group的总的order income
   */
  @Expose()
  order_income: number = 0;

  /**
   * 该group的总的剩余放款金额
   */
  @Expose()
  remaining_amount: number = 0;

  /**
   * 该group的总的实际已还金额
   */
  @Expose()
  repayment_amount: number = 0;

  /**
   * 该group的总的未还金额
   */
  @Expose()
  outstanding_amount: number = 0;

  /**
   * 电子发票图片URL，若没有电子发票则为空字符
   */
  @Expose()
  receipt_img_url: string = '';

  @Expose()
  charge_mode: number = 2;

  @Expose()
  total_interest_payable: number = 2;
}

export class AdvanceInfoOrderListModel {
  @Expose()
  page_info: PageInfo = {
    total: 0,
    page_index: 0,
    has_more: true,
    page_size: 10,
  };

  @Expose()
  list: OrderListList[] = [];
}

export class PageInfo {
  total!: number;
  page_index!: number;
  has_more!: boolean;
  page_size!: number;
}

export class OrderListList {
  /**
   * 订单表的ID，请使用这个ID置入cursor
   */
  @Expose()
  id!: number;
  /**
   *
   */
  @Expose()
  mortgage_amount!: number;
  /**
   * Unpaid = 0 Repaid = 1 PartiallyRepaid = 2 Overdue = 3
   */
  @Expose()
  repayment_status!: number;
  /**
   * @deprecated in refactor
   * 链接怎么实现？
   */
  @Expose()
  disbursement_amount!: number;
  /**
   *
   */
  @Expose()
  actual_disburse_amount!: number;
  /**
   *
   */
  @Expose()
  is_overdue!: boolean;
  /**
   * @deprecated in refactor
   */
  @Expose()
  due_date!: number;
  /**
   * 逾期时间（若发生逾期）
   */
  @Expose()
  overdue_time!: number;
  /**
   * 结清时间（未结清的单为0）
   */
  @Expose()
  clear_time!: number;
  /**
   * 还款日（放款时间+固定时间如14day）
   */
  @Expose()
  repay_time!: number;
  /**
   * @deprecated in refactor
   */
  @Expose()
  repayment_date!: number;
  /**
   * 已还利息+本金
   */
  @Expose()
  principal_outstanding_amount!: number;
  /**
   * @deprecated in refactor
   */
  @Expose()
  disbursed_date!: number;
  /**
   * 实际放款时间
   */
  @Expose()
  actual_disburse_time!: number;
  /**
   *
   */
  @Expose()
  interest_amount!: number;
  /**
   *
   */
  @Expose()
  interest_outstanding_amount!: number;
  /**
   * shopee订单ID
   */
  @Expose()
  entity_id!: number;
  /**
   * @deprecated in refactor
   * 订单ID，请使用这个ID置入cursor
   */
  @Expose()
  fast_escrow_id!: number;
  /**
   *
   */
  @Expose()
  repayment_id!: number;
  /**
   * 订单序列码
   */
  @Expose()
  order_sn!: number;
  /**
   *
   */
  @Expose()
  order_image!: number;
  /**
   * 订单链接
   */
  @Expose()
  order_url!: number;
  /**
   * @deprecated in refactor
   */
  @Expose()
  order_status!: number;
  /**
   *
   */
  @Expose()
  total_outstanding_amount!: number;
  /**
   *
   */
  @Expose()
  total_repayment_amount!: number;
  /**
   * @deprecated in refactor
   * 最后一次还款时间
   */
  @Expose()
  last_repayment_date!: number;
  /**
   * 最后一次还款时间
   */
  @Expose()
  last_repayment_time!: number;
}
