import { Expose } from 'class-transformer';
import { defaultStartDate, defaultEndDate } from '@/stores/transaction/const';
import { ORDER_STATUS } from './order.interface';

export class OrderList {
  @Expose()
  settle_status!: number;

  /* 0: 未逾期  1：逾期 */
  @Expose()
  is_overdue!: number;

  /* 展示用的金额统一此字段取值，后端帮算好了 */
  @Expose()
  total_amount!: number;

  /* 订单序列码 */
  @Expose()
  order_sn!: string;

  /**
   * @deprecated in refactor
   * id
   */
  @Expose()
  id!: string;

  /**
   * @deprecated in refactor
   * settle_id
   */
  @Expose()
  settle_id!: string;

  /**
   * 结算计划id
   */
  @Expose()
  plan_id!: string;

  /**
   * 未还金额
   */
  @Expose()
  outstanding_amount!: string;

  /* 订单的放款金额，没有扣掉服务费的 */
  @Expose()
  principal_amount!: string;

  /* 订单的剩余放款金额 */
  @Expose()
  remaining_disburse_amount!: string;

  /* 订单的实际已还金额 */
  @Expose()
  repayment_amount!: string;

  /* 订单的最终金额 */
  @Expose()
  order_income!: string;

  /* 订单的图片链接 */
  @Expose()
  order_image!: string;

  /* 订单跳转第三方的链接 */
  @Expose()
  order_url!: string;

  /* 服务费 */
  @Expose()
  service_charge_amount!: string;

  /* 服务费率 */
  @Expose()
  service_rate!: string;

  /* 利率 */
  @Expose()
  interest_rate!: string;

  /* 利息 */
  @Expose()
  interest_amount!: string;

  /* 结清日期 */
  @Expose()
  clear_time!: string;

  /* UI的last transaction date的时间戳，单位：秒 */
  @Expose()
  last_transaction_time!: string;

  /* 还款日 */
  @Expose()
  repay_time!: string;
}

export class PageInfo {
  @Expose()
  total!: number;

  @Expose()
  page_index!: number;

  @Expose()
  has_more!: boolean;

  @Expose()
  page_size!: number;
}
/**
 *https://mock.test.shopeekredit.co.id/project/121/interface/api/3044
 *
 * @export
 * @class OrderModel
 */
export class OrderModel {
  @Expose()
  list: OrderList[] = [];

  @Expose()
  page_info!: PageInfo;

  /**
   * @deprecated in refactor
   */
  @Expose()
  dataSource: any[] = [];

  @Expose()
  has_more: boolean = true;

  /**
   * @deprecated in refactor
   */
  @Expose()
  total: number = 0;

  /* 所有list的金额之和。只有page_index=1时才会返回 */
  @Expose()
  total_amount: number = 0;

  /* list所有订单的预估订单金额之和。只有page_index=1时才会返回 */
  @Expose()
  total_mortgage_amount: number = 0;

  /* list所有订单的实际已还金额之和。只有page_index=1时才会返回 */
  @Expose()
  total_repayment_amount: number = 0;

  /* list所有订单的未还金额之和。只有page_index=1时才会返回 */
  @Expose()
  total_outstanding_amount: number = 0;

  @Expose()
  page_index: number = 0;

  @Expose()
  page_size: number = 15;

  @Expose()
  start_time: Date = defaultStartDate;

  @Expose()
  end_time: Date = defaultEndDate;

  @Expose()
  status: ORDER_STATUS = ORDER_STATUS.ALL;

  @Expose()
  loading: boolean = false;

  @Expose()
  whateverSendrequest: boolean = false;
}

export class OrderInfo {
  /**
   * ADVANCE_DISBURSED = 0; SETTLE_PENDING = 1; SETTLE_DISBURSING = 2; SETTLE_DISBURSED = 3; PARTIAL_REPAID = 4; REPAID = 5
   */
  @Expose()
  settle_status: number = 0;

  /**
   * 订单的一阶段放款金额，UI 的 anticipate_amount，没有扣掉服务费的
   */
  @Expose()
  principal_amount: number = 0;

  /**
   * 实际放款金额，扣除服务费
   */
  @Expose()
  actual_disburse_amount: number = 0;

  /**
   * 0: 未逾期  1：逾期
   */
  @Expose()
  is_overdue: boolean = false;

  @Expose()
  is_received_settle_notify: boolean = false;

  /**
   * 剩余放款金额：结算之后需要继续放款的金额
   */
  @Expose()
  remaining_disburse_amount: string = '0';

  /**
   * 应还金额
   */
  @Expose()
  outstanding_amount: number = 0;

  /**
   * 订单序列码
   */
  @Expose()
  order_sn: string = '';

  /**
   * 利率
   */
  @Expose()
  interest_rate: string = '';

  /**
   * 逾期时间戳
   */
  @Expose()
  repay_time: number = 0;

  /**
   * 一阶段放款时间
   */
  @Expose()
  actual_advance_time: number = 0;

  @Expose()
  interest: number = 0;

  /* th新增 */
  @Expose()
  service_charge_amount: number = 0;

  /**
   * 二阶段放款成功时间
   */
  @Expose()
  remaining_disburse_time: number = 0;

  /**
   * 实际放款比例
   */
  @Expose()
  disburse_percentage: number = 0;

  /* th新增是否调整过放款百分比 */
  @Expose()
  is_adjusted?: boolean;

  /**
   * 跳转到第三方页面的链接
   */
  @Expose()
  order_link!: string;

  /**
   * 不同状态，计算规则不同
   */
  @Expose()
  total_amount!: number | string;

  /**
   * 结清时间
   */
  @Expose()
  clear_time: number = 0;

  /* 部分还款时的还款金额 */
  @Expose()
  partially_repaid_amount!: number;

  /* 订单收益 */
  @Expose()
  order_income!: number;

  /* 订单id */
  @Expose()
  entity_id!: string;

  /* 订单的实际已还金额 */
  @Expose()
  repayment_amount!: string;

  /**
   * @deprecated in refactor
   */
  @Expose()
  order_id!: number;
  settle_id?: number;

  /* PH discount_charge */
  @Expose()
  interest_amount: number = 0;

  /* PH order_info的Last Transaction Date */
  @Expose()
  last_transaction_time: number = 0;

  @Expose()
  charge_mode: number = 2;

  /* fdd扣款金额（去除溢缴款金额） */
  @Expose()
  fdd_amount: string = '0';

  /* fdd扣款成功时间 */
  @Expose()
  fdd_deduct_time: number = 0;

  /* 总扣款金额（订单所有扣款单金额的总和，去除溢缴款） */
  @Expose()
  total_deduction_amount: string = '0';

  /* 最后一次扣款时间（排除FDD） */
  @Expose()
  last_auto_deduct_time: number = 0;

  /* 主动还款金额（去除溢缴款） */
  @Expose()
  proactive_repayment_amount: string = '0';

  /* 主动还款时间 */
  @Expose()
  proactive_repayment_time: number = 0;

  // 订单金额
  @Expose()
  mortgage_amount: string = '0';

  // 结算金额
  @Expose()
  settlement_amount: string = '0';

  // 商家最终收到的金额
  @Expose()
  total_received_amount: string = '0';

  // 收到repay之后的扣款
  @Expose()
  deduction: string = '0';
}
