export enum ORDER_STATUS {
  ALL = 0,
  PENDING = 1,
  OVERDUE = 2,
  COMPLETED = 3,
}

export enum ORDER_SETTLE_STATUS {
  ADVANCE_DISBURSED = 0,
  SETTLE_PENDING = 1,
  SETTLE_DISBURSING = 2,
  SETTLE_DISBURSED = 3,
  PARTIAL_REPAID = 4,
  REPAID = 5,
}

/**
 * settle status 映射
 */
export const OrderSettleStatusMap: { [key: number]: string } = {
  [ORDER_SETTLE_STATUS.ADVANCE_DISBURSED]: 'order_info.advance_disbursed',
  [ORDER_SETTLE_STATUS.SETTLE_PENDING]: 'order_info.advance_disbursed',
  [ORDER_SETTLE_STATUS.SETTLE_DISBURSING]: 'order_info.advance_disbursed',
  [ORDER_SETTLE_STATUS.SETTLE_DISBURSED]: 'order_info.fully_disbursed',
  [ORDER_SETTLE_STATUS.PARTIAL_REPAID]: 'order_info.partially_repaid',
  [ORDER_SETTLE_STATUS.REPAID]: 'order_info.fully_repaid',
};

export enum REFACTOR_ORDER_SETTLE_STATUS {
  ADVANCE_DISBURSED = 1,
  PARTIAL_DEDUCTED = 2,
  FULLY_DEDUCTED = 3,
  ORDER_COMPLETED = 4,
  PENDING_TO_SETTLE_DISBURSE = 5,
  SETTLE_DISBURSED = 6,
  PARTIAL_REPAID = 7,
  FULLY_REPAID = 8,
}

/**
 * settle status 映射
 */
export const RefactorOrderSettleStatusMap: { [key: number]: string } = {
  [REFACTOR_ORDER_SETTLE_STATUS.ADVANCE_DISBURSED]: 'order_info.advance_disbursed',
  [REFACTOR_ORDER_SETTLE_STATUS.ORDER_COMPLETED]: 'order_info.advance_disbursed',
  [REFACTOR_ORDER_SETTLE_STATUS.PENDING_TO_SETTLE_DISBURSE]: 'order_info.advance_disbursed',
  [REFACTOR_ORDER_SETTLE_STATUS.SETTLE_DISBURSED]: 'order_info.fully_disbursed',
  [REFACTOR_ORDER_SETTLE_STATUS.PARTIAL_DEDUCTED]: 'order_info.partially_repaid',
  [REFACTOR_ORDER_SETTLE_STATUS.PARTIAL_REPAID]: 'order_info.partially_repaid',
  [REFACTOR_ORDER_SETTLE_STATUS.FULLY_DEDUCTED]: 'order_info.fully_repaid',
  [REFACTOR_ORDER_SETTLE_STATUS.FULLY_REPAID]: 'order_info.fully_repaid',
};
