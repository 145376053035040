import { makeObservable, action, observable } from 'mobx';
import { BaseService } from '../base';
import { PercentageResponseModel } from './model';
import Percentage from './interface';

export class CommonService extends BaseService {
  @observable percentageState: PercentageResponseModel = new PercentageResponseModel();

  constructor() {
    super();
    makeObservable(this);
  }

  @action
  async getPercentageInfo(current_user_percentage: number | string) {
    const { code, result } = await this.post(Percentage.API.get_edit_disburse_percentage_info, {
      body: { current_user_percentage },
    });
    if (code === 0) {
      this.percentageState = {
        ...this.percentageState,
        ...result,
      };
    }
  }
}
