// 业务类别
export enum BIZ_TYPE {
  None = 0,
  Buyer = 1,
  Seller = 2,
  ShopeePayLater = 3,
  FastEscrow = 4,
}

export enum DR_STATUS {
  Normal = 1,
  Frozen = 2,
}

export enum MIGRATE_STATUS {
  Initial = 0,
  First_Data_Migrating = 1,
  First_Data_Migrated = 2,
  Waitting_Second_Data_Migrate = 3,
  Second_Data_Migrating = 4,
  Migration_Done = 5,
  Migrate_Checking = 6,
  User_Frozen = 7,
}

export enum UC_USER_STATUS {
  NONE = 0,
  NOT_EXISTS = 10,
  RESUBMIT = 11,
  UNDER_REVIEW = 12,
  SIGN_CONTRACT = 13,
  PENDING_LC = 14,
  NORMAL = 20,
  FRAUD = 30,
  UPDATEINFO = 31,
  OVERDUE = 32,
  RESTRUCTURED = 33,
  FREEZE_OTHER = 34,
  FREEZE_UPDATE_INFO_PENDING = 35,
  AIRPAY_UNBINDIN_FROZEN = 36,
}

export enum UC_SCREEN_STATUS {
  UNKNOW = 0,
  PENDING = 1,
  APPROVE = 2,
  REJECTED = 3,
}

export enum UC_TOGGLE_STATUS {
  DISABLED = 1,
  ENABLED = 2,
}

export enum UC_OTP_SCENE {
  NONE = 0,
  APPLY = 1,
  ACTIVATION = 2,
}

// https://confluence.shopee.io/display/SK/%5Ball%5D+get_init_user_info_for_credit LockInfo
export enum LOCK_TYPE {
  NONE = 0,
  POST_KYC = 1,
  POST_FRAUD = 2,
  OVERDUE = 3,
  RESTRUCTURE = 4,
  AIRPAY_UNBINDING = 5,
  UNDER_WRITING = 6, // 授信
  COLLECTION = 7, // 催收
  SELLER_FREEZE = 8, // BR
  CNPJ_FREEZE = 9, // BR
  IC_FREEZE = 10, // BR
  LICENSE = 11,
  PRODUCT_UPGRADE = 12,
  CLOSURE = 13, // 账户关闭冻结
  DR_DISABLE_USER = 14, // dr禁用用户
  UNLINK_CARD = 15, // SG未绑卡冻结
  FS_KYC_FROZEN = 16, // BR fes kyc frozen
  TESTING_ACCOUNT_FREEZE = 17, // Live Testing账号冻结
  SHOPEE_STATUS_FROZEN = 18, // shopee状态冻结
  FOODY_STATUS_FROZEN = 19, // foody状态冻结
  NANO_LICENSE = 20,
  DPD60 = 26, // dpd60
  PAYMENT_REJECTION_FREEZE = 29, // 支付拒绝冻结
}

// 是否存量用户
export enum IS_STOCK_USER {
  FALSE = 0,
  TRUE = 1,
}

// kyc冻结 1-正常；2-冻结
export enum KYC_STATUS {
  UNKNOWN = 0,
  // 正常
  NORMAL = 1,
  // 冻结
  FROZEN = 2,
}

// 风控反欺诈冻结 1-正常；2-冻结
export enum FRAUD_STATUS {
  UNKNOWN = 0,
  // 正常
  NORMAL = 1,
  // 冻结
  FROZEN = 2,
}

// 逾期冻结 1-正常；2-冻结
export enum OVERDUE_STATUS {
  UNKNOWN = 0,
  // 正常
  NORMAL = 1,
  // 冻结
  FROZEN = 2,
}

// 用户是否首次激活
export enum IS_FIRST_IN {
  FALSE = 0, // 不，不用弹框
  TRUE = 1, // 是，前端首页弹框
}

// 风控拒绝码（risk_reject_code）
export enum RISK_REJECT_CODE {
  NONE = '',

  OVERDUE = 'AAR01', // Have overdue bills

  UNDERWRITING = 'AUC01', // Underwriting: Rejected by underwriting policy
  PAYMENT_REJECT_COOL_DOWN_CODE1 = 'DAC01',
  PAYMENT_REJECT_COOL_DOWN_CODE2 = 'DAC02',

  FRAUD_COOL_DOWN1 = 'AAC01', // Anti-fraud: Hit anti-fraud rules - cool down
  FRAUD_COOL_DOWN2 = 'KAC01', // Anti-fraud: Hit anti-fraud rules - cool down
  IC_PHOTO_OR_NOT_QUALIFIED = 'KAR02', // Anti-fraud: IC photo or selfie is not qualified
  FACE_ERROR = 'KAR03', // Anti-fraud: Face not match (need user center)
  IC_INVALIDED = 'KAR04', // Anti-fraud: IC not valid
  KYC_NOT_MATCH = 'KAR05', // Anti-fraud: KYC information not match
  FRAUD_BAN_APP1 = 'KAB01', // Anti-fraud: Hit anti-fraud rules - ban application
  FRAUD_BAN_APP2 = 'AAB01', // Anti-fraud: Hit anti-fraud rules - ban application

  NO_COOL_TIME1 = 'ADR01', // Default: If didn't configure the rejection code, default as this rejection code
  NO_COOL_TIME2 = 'KDR01', // Default: If didn't configure the rejection code, default as this rejection code
  VERIFICATION_COOL_DOWN = 'KAC02', // Fail too many times of verification-cool down
  SYSTEM_ISSUE = 'SER01', // Rejected due to system issue

  KYC_INACCURATE_INFO = 'UCKYC05',
  KYC_ID_BROKEN1 = 'UCKYC07',
  KYC_ID_BROKEN2 = 'UCKYC08',
  KYC_ID_BROKEN3 = 'UCKYC09',
  KYC_ID_BROKEN4 = 'UCKYC10',
  KYC_ID_BROKEN5 = 'UCKYC11',
  KYC_ID_BROKEN6 = 'UCKYC12',
  KYC_ID_BROKEN7 = 'UCKYC13',
  KYC_AGE = 'UCKYC14', // 用户年龄不是18-65岁

  UNDEFINED = 'UNDEFINED', // 风控返回的拒绝码是空字符串或者不在上面列出的那些拒绝码之内，则自定义为UNDEFINED
}

export const ID_BROKEN_CODES = [
  RISK_REJECT_CODE.KYC_ID_BROKEN1,
  RISK_REJECT_CODE.KYC_ID_BROKEN2,
  RISK_REJECT_CODE.KYC_ID_BROKEN3,
  RISK_REJECT_CODE.KYC_ID_BROKEN4,
  RISK_REJECT_CODE.KYC_ID_BROKEN5,
  RISK_REJECT_CODE.KYC_ID_BROKEN6,
  RISK_REJECT_CODE.KYC_ID_BROKEN7,
];

export enum FROM_SOURCE_MAP {
  ENTRY = 'financial_service_option',
  SELLER_MEPAGE = 'seller',
  SELLER_WALLET = 'seller_wallet',
  PUSH_NOTIFICATION = 'pn',
  OTHER = 'other',
}

export enum PerfEventType {
  getAuthCodeFail = 13,
  getInitInfoFail = 14,
}

export enum CodeType {
  AuthCode = 1,
  OnceCode = 2,
}

// exit_state: ABORTED = 1, FAILED = 2, SUCCESS = 3
export enum ExitState {
  Aborted = '1',
  Failed = '2',
  Success = '3',
}
