import { Expose } from 'class-transformer';
import type {
  IS_FIRST_POPUP,
  FEE_CHANGE_TYPE,
  IOnboardingLimitAdjustInfo,
  IUserTieredFeeInfo,
  ITemporaryLimitAdjustInfo,
} from './home.interface';
import { UC_TOGGLE_STATUS } from '../userInfo/userInfo.interface';

export enum InvoiceToggleStatus {
  Ignore = 0,
  Open = 2,
  close = 1,
}

export enum InvoiceWhiteList {
  Hidden = 1,
  Show = 2,
}

export class HomeModel {
  @Expose()
  overdue_outstanding_amount: number = 0; // 逾期冻结金额

  @Expose()
  total_limit: number = 0; // 用户可以从 fast escrow 借到的钱的最大限制

  @Expose()
  available_limit: number = 0; // 用户当前还可以从 fast escrow 借到多少钱的最大限制

  @Expose()
  frozen_amount: number = 0; // 放款中金额

  @Expose()
  used_amount: number = 0; // 已用金额，对应前端显示的 outstanding_funds

  @Expose()
  user_percentage: number = 0; // 用户设置的比例

  @Expose()
  system_percentage: number = 0; // 风控返回的比例

  @Expose()
  schedule_disburse_percentage: number = 0; // min(用户设置的比例，风控返回的比例)

  /**
   * // 比例可以调整的最大值
   * @type {number}
   * @memberof HomeModel
   * @deprecated
   */
  @Expose()
  percentage_max: number = 0;

  /**
   * // 比例可以调整的最小值
   * @type {number}
   * @memberof HomeModel
   * @deprecated
   */
  @Expose()
  percentage_min: number = 0;

  @Expose()
  fast_escrow_user_id: number = 0; // 用户ID

  @Expose()
  free_interest_type: number = 0; // 费率优惠类型

  @Expose()
  free_interest_end_time: number = 0; // 费率优惠的最后时间

  @Expose()
  is_in_free_interest_period: boolean = false; // 是否在费率优惠期

  @Expose()
  free_interest_promotion_days: number = 0; // 免息期长度，例如7天或14天

  @Expose()
  disbursement_cutoff_time: string = ''; // 每天的放款时间，格式为 HH:MM

  @Expose()
  today_disburse_time: number = 0; // Today's Disburse time

  @Expose()
  to_be_received_amount: number = 0; // 下一次放款将要收到的放款金额，扣除了服务费的

  /**
   * @type {number}
   * @memberof HomeModel
   * @deprecated
   */
  @Expose()
  to_be_received_disbursement_amount: number = 0; // disbursement amount

  @Expose()
  toggle_status: UC_TOGGLE_STATUS = 1; // 是否开启服务

  @Expose()
  idle_user_tag: number = 0;

  @Expose()
  idle_user_promotion_tag: number = 0; // 是否处于闲置用户优惠期间，0 否，1 是

  @Expose()
  idle_user_promotion_ttl: number = 0; // 处于闲置用户优惠的剩余时间 单位 s

  @Expose()
  is_first_popup!: IS_FIRST_POPUP; // BR用户降费是否是第一次弹窗,0 false, 1true

  @Expose()
  fee_change_type!: FEE_CHANGE_TYPE; // BR用户费率调整类型，0 默认值、 1 PS用户变成NPS用户提升费率、 2 NPS用户变成PS用户降低费率 3 OS用户变成NOS用户提升费率、 4 NOS用户变成OS用户降低费率

  @Expose()
  service_fee_rate: number = 0; // 用户费率

  @Expose()
  idle_user_free_interest_days: number = 0;

  @Expose()
  service_charge_discount_rate?: number = 100;

  @Expose()
  user_tiered_fee_info: IUserTieredFeeInfo = {} as IUserTieredFeeInfo;

  @Expose()
  onboardingLimitAdjust: IOnboardingLimitAdjustInfo = {} as IOnboardingLimitAdjustInfo;

  @Expose()
  temporaryLimitAdjust: ITemporaryLimitAdjustInfo = {} as ITemporaryLimitAdjustInfo;

  @Expose()
  queryResponseTime?: number;

  @Expose()
  individual_invoice_toggle_status?: InvoiceToggleStatus = InvoiceToggleStatus.close;

  @Expose()
  individual_invoice_white_list?: InvoiceWhiteList = InvoiceWhiteList.Hidden;
}
