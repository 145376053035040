namespace Home {
  export enum API {
    'get_homepage' = '/fast-escrow/order/homepage',
    'get_limit_adjust_info' = '/fast-escrow/usercenter/bff/get_user_credit_limit_change',
    'commit_limit_change' = '/fast-escrow/usercenter/bff/commit_limit_change',
    'update_user_config' = '/fast-escrow/order/update_user_config',
    'update_first_activate' = '/fast-escrow/usercenter/bff/commit_first_activate_account',
    'get_user_latest_disbursed_groups' = '/fast-escrow/order/get_user_latest_disbursed_groups',
    'get_user_mobile_number' = '/fast-escrow/usercenter/bff/get_user_mobile_number',
  }

  export enum RefactorAPI {
    'get_homepage' = '/fast-escrow/transaction/query_homepage',
    'update_user_config' = '/fast-escrow/transaction/update_user_config',
    'get_user_latest_disbursed_groups' = '/fast-escrow/transaction/query_latest_groups',
    'get_fe_product_config' = '/fast-escrow/transaction/get_fe_product_config',
  }
}

export default Home;
