import { makeObservable, action, observable } from 'mobx';
import { BaseService } from '../base';
import { UpcomingResponseModel, UpcomingListModel } from './model';
import Upcoming from './interface';

export class CommonService extends BaseService {
  @observable loaded: boolean = true;
  @observable hasMore: boolean = true;
  @observable upcomingState: UpcomingResponseModel = new UpcomingResponseModel();

  @observable dataList: UpcomingListModel[] = [];

  protected page_index = 0;
  protected page_size = 10;

  constructor() {
    super();
    makeObservable(this);
  }

  @action
  initInfo() {
    this.loaded = true;
    this.hasMore = true;
    this.page_index = 0;
    this.page_size = 10;
    this.dataList = [];
  }

  @action
  async fetchInfo() {
    // 如果已经标记当前状态为hasmore：false
    if (!this.hasMore || !this.loaded) return;
    this.loaded = false;

    const { code, result } = await this.post(Upcoming.API.get_user_candidate_orders_summary, {
      body: {
        pagination: {
          page_index: this.page_index + 1,
          page_size: this.page_size,
          cursor:
            this.dataList.length > 0
              ? this.dataList?.[this.dataList.length - 1]?.fast_escrow_id ?? 0
              : 0,
        },
      },
    });
    if (code === 0) {
      const { page_info, list = [], ...info } = result;

      this.dataList = (list ?? []).map((item: UpcomingListModel) => {
        // refactor后接口fast_escrow_id改为id
        item.fast_escrow_id = item.fast_escrow_id || item.id;
        return item;
      });
      this.hasMore = page_info.has_more;
      this.page_index = page_info.page_index;
      this.upcomingState = {
        ...this.upcomingState,
        ...result,
      };
      this.loaded = true;
      return result;
    }
    this.loaded = true;
    this.hasMore = false;
  }
}
