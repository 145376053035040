import { Expose } from 'class-transformer';
import type { IRepayOrder } from './repayment.interface';

export class RepaymentModel {
  // 所有可还款金额 = 逾期部分总金额 + DOB 10+部分总金额
  @Expose()
  total_amount!: number;

  @Expose()
  overflow_amount!: number;

  @Expose()
  overdueInfo!: IRepayOrder;

  @Expose()
  repayableInfo!: IRepayOrder;

  @Expose()
  selectedChannelName!: string;

  @Expose()
  selectedChannelId!: number;

  @Expose()
  payOption!: string;

  @Expose()
  loading!: boolean;
}
