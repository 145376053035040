import { ENV, MdapSdk, CustomPlugin } from '@mdap/javascript-sdk';
import APIPlugin from '@mdap/sdk-plugin-api';
import PagePerfPlugin from '@mdap/sdk-plugin-performance';
import ResourcePlugin from '@mdap/sdk-plugin-resource';
import ExceptionPlugin from '@mdap/sdk-plugin-exception';
import { v4 } from 'uuid';

// 一般错误信息
export enum ICustomMessage {
  'ERROR_CATCH_BY_ERROR_BOUNDARY' = 'ERROR_CATCH_BY_ERROR_BOUNDARY', // react error boundary 中捕获的错误
  'API_INFO' = 'API_INFO', // 接口成功时上报info级别信息
  'API_CODE_ERROR' = 'API_CODE_ERROR', // 接口逻辑失败(code 非0)
  'API_MESSAGE_ERROR' = 'API_MESSAGE_ERROR', // 接口逻辑失败
  'API_UNCAUGHT_ERROR' = 'API_UNCAUGHT_ERROR', // api请求发生未预期错误
  'DEVICE_FINGERPRINT_TOKEN_ERROR' = 'DEVICE_FINGERPRINT_TOKEN_ERROR',
  'BRIDGE_CALL_INFO' = 'BRIDGE_CALL_INFO', // bridge 调用
}

// 全局tag
export enum ICustomTag {
  'USER_TRACK_ID' = 'USER_TRACK_ID',
  'USER_ID' = 'USER_ID',
}

type ExceptionLevel = 'error' | 'warn' | 'info' | 'debug';

/**
 * 自定义异常上报数据配置
 *
 * @export
 * @interface IMdapCustomExcetion
 */
export interface IMdapCustomExcetion {
  message?: ICustomMessage | string;
  error?: Error | unknown;
  data?: {
    name?: string;
    level?: ExceptionLevel;
    data_field?: Record<string, string>;
    extra?: Record<string, any>;
  };
}

export interface IMdapCustomRepoter {
  point_id: string;
  duration?: number;
  data: Record<string, any>;
}

export enum IMdapEnv {
  dev = 'test',
  mock = 'test',
  test = 'test',
  uat = 'test',
  live = 'live',
}

export type IMdapRegion = 'sg' | 'my' | 'th' | 'vn' | 'ph' | 'tw' | 'id' | 'br';

export type IMdapOptions = {
  useLogger?: boolean;
  sample?: number;
  usePlugin?: boolean;
};

export interface ApiErrorParams {
  traceId: string;
  apiPath: string;
  options?: any;
  response?: any;
  [extra: string]: any;
}

// type IKeys = {
//   [key: string]: { name: string; key: string };
// };

/**
 * Mdap 监控
 *
 * @export
 * @class MDAP
 */
export class MDAP {
  protected sdk: MdapSdk;

  private static instance: MDAP;

  private exceptReporter?: ExceptionPlugin;

  private customReporter?: CustomPlugin;

  private apiPlugin?: APIPlugin;

  protected name: string;

  protected key: string;

  protected version: string;

  constructor(
    env: keyof typeof IMdapEnv,
    region: IMdapRegion,
    options: IMdapOptions = {
      useLogger: true,
      usePlugin: true,
    },
  ) {
    // try {
    //   this.initAppNameAndKey(env);
    // } catch (err) {
    //   console.warn(`mdap 启动失败: ${err}`);
    // }
    this.name = MDAP_APP_NAME;
    this.key = MDAP_SDK_SECRET_KEY;
    this.version = MDAP_APP_VERSION;

    this.sdk = new MdapSdk({
      app_name: this.name,

      secret_key: this.key,

      // 上报环境: test | staging | uat | live
      // environment值为 ‘live’ 时，数据会上报到正服环境 https://mdap.shopee.io， 为其他值时数据会上报到测服  https://mdap.exp.shopee.io
      environment: env as ENV,

      // 上报地区/Reporting Region | sg | tw | ph | th | id | my | br | mx | ...
      region: region.toLowerCase(),

      // 是否开启调试日志/Toggle of debug logger | default - false
      logger: options?.useLogger ?? env !== IMdapEnv.live,

      // 业务应用版本 business application version
      app_version: this.version,

      sample: options?.sample ?? 1,
    });

    // 插件启动
    if (options.usePlugin) {
      this.useExceptionPlugin()
        .usePagePlugin()
        .useApiPlugin()
        .useResourcePlugin()
        .useCustomPlugin();
      this.trackFetch();
    }
    this.addUserTrackTag();
  }

  /**
   *
   * 自定义异常上报
   *
   * @param {IMdapCustomExcetion} customData
   * @memberof MDAP
   */
  public static report(customData: IMdapCustomExcetion) {
    this?.instance?.exceptReporter?.capture(customData as any);
  }

  public static customReport(customReportData: IMdapCustomRepoter) {
    this?.instance?.customReporter?.sendData(customReportData);
  }

  public static customPVReport(reportData: Record<string, any>) {
    const point_id =
      REACT_APP_ENV !== 'live'
        ? '8575e60fd3c5da51ee599f241a388350'
        : 'a016dcab68af63be5bcbaa2f165c7301';

    this?.customReport({
      point_id,
      data: {
        ...reportData,
        _product: this.name,
      },
    });
  }

  public static loginFailReport() {
    const point_id =
      REACT_APP_ENV !== 'live'
        ? 'e133dccf45d1edf232176d01428ef1cd'
        : 'c00a2d7a6a3e3779f05b1f84b05b9d15';

    this?.customReport({
      point_id,
      data: {},
    });
  }

  // api 逻辑成功时上报api信息
  public static apiInfoReport({ traceId, apiPath }: { traceId: string; apiPath: string }) {
    // 暂时不上报
    // this.report({
    //   message: ICustomMessage.API_INFO,
    //   data: {
    //     level: 'info',
    //     data_field: {
    //       type: 'success',
    //       trace_id: traceId,
    //       api_path: apiPath,
    //     },
    //   },
    // });
  }

  /**
   * api 失败
   * @param param0
   * @param message
   * @param leave(默认warn，业务关键节点error)
   */
  public static apiErrorReport(
    { traceId, apiPath, options, response, ...rest }: ApiErrorParams,
    message = ICustomMessage.API_CODE_ERROR,
    level: ExceptionLevel = 'warn',
  ) {
    this.report({
      message,
      data: {
        name: message,
        level,
        data_field: {
          type: 'failed',
          trace_id: traceId,
          api_path: apiPath,
        },
        extra: {
          options,
          response,
          ...rest,
        },
      },
    });
  }

  /**
   * 本次访问的所有上报数据都会加上一个track_id以便追踪
   */
  private addUserTrackTag() {
    this.sdk.addEntryTag(ICustomTag.USER_TRACK_ID, v4());
  }

  /**
   * 本次访问的所有上报数据都会加上一个user_id以便追踪
   */
  public static addUserIdTag(userId: string) {
    this.instance?.sdk?.addEntryTag(ICustomTag.USER_ID, userId);
  }

  /**
   * bridge 调用info级别上报
   * @param params bridge调用时的参数
   */
  public static bridgeCallReport(...params: any) {
    try {
      this.report({
        message: ICustomMessage.BRIDGE_CALL_INFO,
        data: {
          name: ICustomMessage.BRIDGE_CALL_INFO,
          level: 'info',
          data_field: {},
          extra: {
            ...params,
          },
        },
      });
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * API 监控
   * 默认启动
   *
   * @memberof MDAP
   */
  private useApiPlugin() {
    // api请求监控插件  Plugin of api request
    this.apiPlugin = new APIPlugin({
      useLogicStatus: true,
    });
    this.sdk.use(this.apiPlugin);
    return this;
  }

  private useCustomPlugin() {
    this.customReporter = new CustomPlugin({});
    this.sdk.use(this.customReporter);
    return this;
  }

  /**
   *
   * 静态资源加载监控插件 Plugin of resource loading
   * 默认启动
   *
   * @param {string[]} [path]
   * @memberof MDAP
   */
  private useResourcePlugin(path?: string[]) {
    this.sdk.use(
      new ResourcePlugin({
        path: path ?? ['/static'],
      }),
    );
    return this;
  }

  /**
   *
   *
   * 页面性能监控插件 Plugin of page performance
   * 默认启动
   *
   * @param {string[]} [path]
   * @return {*}
   * @memberof MDAP
   */
  private usePagePlugin(path?: string[]) {
    this.sdk.use(
      new PagePerfPlugin({
        path: path ?? ['/'],
      }),
    );
    return this;
  }

  /**
   *
   * JS 异常信息监控插件
   * 默认启动
   *
   * @return {*}
   * @memberof MDAP
   */
  private useExceptionPlugin() {
    this.exceptReporter = new ExceptionPlugin();
    this.sdk.use(this.exceptReporter);
    return this;
  }

  /**
   * api 逻辑状态码监控
   * mdap 将 _MDAP_DATA_ID_ 设置在 fetch 的 response 对象中，response.clone 后 _MDAP_DATA_ID_ 将会丢失，因此umi-request无法获得_MDAP_DATA_ID_，所以对 fetch 进行拦截上报
   * https://confluence.shopee.io/pages/viewpage.action?pageId=1248475992
   */
  private trackFetch = () => {
    const originalFetch = window.fetch;
    const { apiPlugin } = this;
    window.fetch = function (info, init) {
      const triggerTrack = function (
        response: Response & {
          _MDAP_DATA_ID_?: string;
        },
      ) {
        if (response?._MDAP_DATA_ID_) {
          const { _MDAP_DATA_ID_ } = response;
          response
            .clone()
            .json()
            .then((res) => {
              apiPlugin?.setLogicStatus(_MDAP_DATA_ID_, res?.code);
            })
            .catch((e) => {});
        }
      };
      const onResolve = function (response: Response) {
        triggerTrack(response);
        return response;
      };
      const onReject = function (error: Error) {
        return Promise.reject(error);
      };
      return originalFetch.call(window, info, init).then(onResolve, onReject);
    };
  };

  public static init(env: keyof typeof IMdapEnv, region: IMdapRegion, options?: IMdapOptions) {
    this.instance = new MDAP(env, region, options);
  }
}
