namespace Advance {
  export enum API {
    'get_advance_list' = '/fast-escrow/order/get_user_disburse_groups',
    'get_advance_detail' = '/fast-escrow/order/get_user_disburse_group',
    'my_get_group_orders' = '/fast-escrow/order/my_get_group_orders',
    'get_group_orders' = '/fast-escrow/order/get_group_orders',
    'get_group_settlements' = '/fast-escrow/order/get_group_settlements',
  }

  export enum RefactorAPI {
    'get_advance_list' = '/fast-escrow/transaction/query_disburse_group',
    'get_advance_detail' = '/fast-escrow/transaction/query_disburse_group_detail',
    'get_group_orders' = '/fast-escrow/transaction/query_settle_list',
    'get_user_invoices' = '/fast-escrow/recon/get_user_invoices',
    'get_user_invoices_by_month' = '/fast-escrow/transaction/get_e_invoice_list',
    'get_user_loan_agreement' = '/fast-escrow/transaction/get_user_LFA',
  }
}

export default Advance;
