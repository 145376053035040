import { history } from 'umi';
import { makeObservable, action, observable, runInAction } from 'mobx';
import spmSDK from '@shopeepay/payment-selection-sdk-h5';
import { useFormatMsg } from '@/utils/locale';
import { BaseService } from '../base';
import { Toast } from 'antd-mobile';
import bridge from '@/utils/v2/bridge';
import Repayment from './api.interface';
import { RepaymentModel } from './repayment.model';
import {
  IRepayOrder,
  REPAYABLE_TYPE,
  REFACTOR_REPAY_STATUS,
  MY_REPAY_CHANNEL_ID,
  CodeHandlers,
  IRepayOrderItem,
} from './repayment.interface';
import insight from '@/utils/v2/insight';

const formatMessage = useFormatMsg();

export class RepaymentService extends BaseService {
  @observable repayment: RepaymentModel = {
    total_amount: 0,
    overflow_amount: 0,
    overdueInfo: {} as IRepayOrder,
    repayableInfo: {} as IRepayOrder,
    selectedChannelName: '',
    selectedChannelId: 0,
    payOption: '',
    loading: false,
  } as RepaymentModel;

  constructor() {
    super();
    makeObservable(this);
  }

  @action
  async initRepayInfo() {
    // 初始化
    this.repayment = {
      ...this.repayment,
      total_amount: 0,
      overflow_amount: 0,
      overdueInfo: {} as IRepayOrder,
      repayableInfo: {} as IRepayOrder,
      selectedChannelName: '',
      selectedChannelId: 0,
      payOption: '',
      loading: false,
    };
  }

  @action
  async getRepayInfo({ isForHome }: { isForHome: boolean }) {
    const { code, result } = await this.post(Repayment.API.get_repay_info);
    if (code !== 0 && !isForHome) {
      Toast.show({ icon: 'fail', content: formatMessage({ id: 'system_busy' }) });
      history.push('/home');
      return false;
    }
    const { total_amount = 0, overflow_amount = 0, repayable_order_info_list = [] } = result;
    const overdueInfo = repayable_order_info_list?.find(
      (item: any) => item?.repayable_type === REPAYABLE_TYPE.OVERDUE,
    );
    const repayableInfo = repayable_order_info_list?.find(
      (item: any) => item?.repayable_type === REPAYABLE_TYPE.PARTIALREPAID,
    );
    const list = (repayable_order_info_list || [])?.map((item: any) => {
      const { total_amount: totalAmount, repayable_type } = item;
      return {
        amount_to_pay: totalAmount,
        repayment_type: repayable_type,
      };
    });

    const info = {
      total_amount,
      overflow_amount,
      overdueInfo,
      repayableInfo,
    } as RepaymentModel;

    if (!isForHome) {
      const { code: code2, result: result2 } = await this.post(Repayment.API.get_repay_channel, {
        body: {
          list,
          overflow_amount,
        },
      });

      if (code2 !== 0) {
        return;
      }

      /**
       * 与SPM侧沟通确认上次还款成功渠道取default_selected_payment_channel字段展示
       */
      if (result2?.default_selected_payment_channel) {
        const lastRepayChannelInfo = JSON.parse(result2?.default_selected_payment_channel ?? '{}');
        info.payOption = result2?.default_selected_payment_channel ?? '';
        info.selectedChannelName = lastRepayChannelInfo?.channel_selected_display_text ?? '';
        info.selectedChannelId = lastRepayChannelInfo?.channel_id ?? 0;
      } else {
        info.payOption = '';
        info.selectedChannelName = '';
        info.selectedChannelId = 0;
      }
    }

    runInAction(() => {
      this.repayment = {
        ...this.repayment,
        ...info,
      };
    });
    return true;
  }

  @action
  async getRepayOption({
    list,
    overflow_amount,
    payOption,
  }: {
    list: IRepayOrderItem[];
    overflow_amount: string;
    payOption: string;
  }) {
    Toast.show({ icon: 'loading', content: formatMessage({ id: 'loading' }), duration: 0 });
    const { code, result } = await this.post(Repayment.API.get_repay_channel, {
      body: {
        list,
        overflow_amount,
      },
    });

    if (code !== 0) {
      Toast.clear();
      Toast.show({ icon: 'fail', content: formatMessage({ id: 'system_busy' }) });
      return;
    }

    const {
      channels,
      encrypted_request_param,
      client_id,
      layout_format,
      default_selected_payment_channel,
      recently_used_payment_channel,
    } = result ?? {};

    const lastSelectedPaymentChannelData = JSON.parse(
      default_selected_payment_channel || recently_used_payment_channel || '{}',
    );

    // https://confluence.shopee.io/pages/viewpage.action?pageId=1511099481
    const paymentSdkInitData = {
      channels: JSON.parse(channels) ?? [],
      encryptedRequestParam: encrypted_request_param ?? '',
      clientId: client_id, // SHOPEE_CREDIT_FAST_ESCROW
      source: 'fastescrow',
      pageType: 'full' as const,
      layoutFormat: layout_format,
      // 默认选中的值，用于初始化的时候选中某一项,SPM FE说我们直接透传BE返给我们选中的渠道参数即可
      selectedPaymentChannelData: lastSelectedPaymentChannelData,
      defaultExpanderChannelId: lastSelectedPaymentChannelData?.channel_id, // 默认的展开项，主要用于bank类型
      onConfirm: (data: any) => {
        Toast.clear();
        // 点击confirm按钮的回调，data为用户选择的支付方式
        const { selectedPaymentChannelData, selectedPaymentLabelName } = data ?? {};
        console.log('spm sdk returns', data);
        // 配置告警和监控
        insight.thirdRNSdkReport({
          sdkName: 'payment_selection_sdk',
          method: 'response',
        });
        insight.log({
          message: { selectedPaymentChannelData, selectedPaymentLabelName },
          name: 'payment_selection_sdk',
        });

        const channelDataJson = JSON.stringify(selectedPaymentChannelData);
        // 此次选择和上次选择不一样
        if (channelDataJson && channelDataJson !== payOption) {
          this.repayment = {
            ...this.repayment,
            payOption: channelDataJson,
            selectedChannelName: selectedPaymentLabelName,
          };
        }
      },
    };

    const sdk = await spmSDK.init(paymentSdkInitData); // 初始化SDK， data用来定义相关数据及方法，具体内容见下面描述
    sdk.launch(); // 拉起payment selection page 页面，可传初始化的一些值，用于修改init的内容，非必填参数
    Toast.clear();
  }

  async finishRepayByShopeePayWebPage(repay_url: string) {
    await new Promise((resolve) => {
      bridge.callWebview(repay_url);
      const reappearCb = () => {
        resolve(true);
        bridge.unBind('viewWillReappear', reappearCb);
      };
      bridge.bind('viewWillReappear', reappearCb);
    });
  }

  @action
  async triggerRepay({
    overflow_amount,
    list,
    select_channel,
    repay_channel_name,
  }: {
    overflow_amount: string;
    list: IRepayOrderItem[];
    select_channel: string;
    repay_channel_name: string;
  }) {
    const payOption = select_channel;
    this.repayment.loading = true;

    const { code, msg, result } = await this.post(Repayment.API.proactive_repay, {
      body: {
        overflow_amount,
        list,
        select_channel,
        repay_channel_name,
      },
    });

    const handleFinalState = (proactive_repay_id: string) => {
      this.repayment.loading = false;
      history.push({
        pathname: `/billing/repay-result/${proactive_repay_id ?? ''}`,
      });
    };

    const handleShopeePayOption = (repay_url: string) => {
      this.repayment.loading = false;
      if (!repay_url) {
        Toast.show({ icon: 'fail', content: formatMessage({ id: 'system_busy' }) });
        return;
      }
      bridge.navigateAppRL({
        apprl: repay_url,
        params: {
          __pc__: 1, // pop count, 1表示pop掉当前的webview
        },
      });
    };

    const handleSpmWebSafeOption = async (repay_url: string) => {
      this.repayment.loading = false;
      if (!repay_url) {
        Toast.show({ icon: 'fail', content: formatMessage({ id: 'system_busy' }) });
        return;
      }
      await this.finishRepayByShopeePayWebPage(repay_url);
      history.push({ pathname: '/home' });
    };

    const handleOtherRepayUrl = (repay_url: string) => {
      this.repayment.loading = false;
      window.location.href = repay_url;
    };

    const handleDefault = (proactive_repay_id: string) => {
      this.repayment.loading = false;
      history.push({
        pathname: `/billing/repay-result/${proactive_repay_id ?? ''}`,
      });
    };

    const codeHandlers: CodeHandlers = {
      0: () => {
        const { proactive_repay_id, repay_url, repay_status } = result;
        const isFinalState = [
          REFACTOR_REPAY_STATUS.CANCELLED,
          REFACTOR_REPAY_STATUS.FAILED,
          REFACTOR_REPAY_STATUS.DEDUCTED,
          REFACTOR_REPAY_STATUS.COMPLETE,
        ].includes(repay_status);

        const shopeePayOptions = [MY_REPAY_CHANNEL_ID.SHOPEE_PAY, MY_REPAY_CHANNEL_ID.IPAY88];

        switch (true) {
          case isFinalState:
            // 与BE沟通确认以下这几种状态直接到终态（溢缴款全量抵扣）,直接跳转还款结果页
            handleFinalState(proactive_repay_id);
            break;
          case shopeePayOptions.some((option) => payOption.includes(option)):
            // 如果是shopeePay方式，已与SPM PM确认还款url都是跳转spm rnsafe page
            handleShopeePayOption(repay_url);
            break;
          case repay_url:
            // 其他有repay_url，跳后台返回的url
            handleOtherRepayUrl(repay_url);
            break;
          default:
            // 直接跳还款结果页
            handleDefault(proactive_repay_id);
            break;
        }
      },
      default: () => {
        this.repayment.loading = false;
        Toast.show({ icon: 'fail', content: msg || formatMessage({ id: 'system_busy' }) });
      },
    };

    const handler = codeHandlers[code] || codeHandlers.default;
    handler();
  }

  @action
  async getRepayResult(data: { repay_id: string }) {
    const result = await this.post(Repayment.API.get_repay_result, {
      body: data,
    });
    return result;
  }
}
