import { MDAP, ApiErrorParams, ICustomMessage } from './mdap';
import Home from '@/stores/home/api.interface';

// 关键业务信息
export enum IBusinessMessage {
  'UPDATE_USER_CONFIG_ERROR' = 'UPDATE_USER_CONFIG_ERROR',
}

/**
 * 放款百分比设置失败，累积点上报
 */
export const pointPercentageSetFail = () => {
  const point_id =
    REACT_APP_ENV !== 'live'
      ? '9437eef52a52d49575c7a20e16bbd742'
      : '2f7fb6f96fd058db6a9bb42e30c4a294';

  MDAP?.customReport({
    point_id,
    data: {},
  });
};

/**
 * 首页服务开关失败，累积点上报
 */
export const pointServiceToggleFail = (type: string) => {
  const point_id =
    REACT_APP_ENV !== 'live'
      ? 'fdb35c7842b31f44eaca231353d599f6'
      : '6ca16c0b30bbde3dfe6b5633c40be536';

  MDAP?.customReport({
    point_id,
    data: {
      toggle_type: type,
    },
  });
};

export const businessAPIToMessageMap: any = {
  [Home.RefactorAPI.update_user_config]: IBusinessMessage.UPDATE_USER_CONFIG_ERROR,
  [Home.API.update_user_config]: IBusinessMessage.UPDATE_USER_CONFIG_ERROR,
};

export const MDAPApiErrorReport = (params: ApiErrorParams, message?: ICustomMessage) => {
  const { apiPath } = params;
  if (businessAPIToMessageMap[apiPath]) {
    // 关键业务错误，上报error级别
    MDAP.apiErrorReport(params, businessAPIToMessageMap[apiPath], 'error');
  } else {
    // 普通api错误
    MDAP.apiErrorReport(params, message);
  }
};
