namespace Percentage {
  export enum API {
    'get_edit_disburse_percentage_info' = '/fast-escrow/order/get_edit_disburse_percentage_info',
  }

  export enum RefactorAPI {
    'get_edit_disburse_percentage_info' = '/fast-escrow/transaction/query_edit_disburse_percentage',
  }
}

export default Percentage;
