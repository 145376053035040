import { Toast } from '@credit/tips';
import { makeObservable, action, observable } from 'mobx';
import { BaseService } from '../base';
import { AdvanceModel, AdvanceInfoModel, AdvanceInfoOrderListModel } from './advance.model';
import dayjs from '@/utils/dayjs';
import { formatMessage } from '@/utils/locale';
import Advance from './api.interface';

export class CommonService extends BaseService {
  @observable advanceList: AdvanceModel = new AdvanceModel();

  // 详情
  @observable advanceInfo: AdvanceInfoModel = new AdvanceInfoModel();

  // order list
  @observable infoOrderList: AdvanceInfoOrderListModel = new AdvanceInfoOrderListModel();
  @observable orderLoaded: boolean = true;

  constructor() {
    super();
    makeObservable(this);
  }

  @action
  async initAdvanceList() {
    this.advanceList = new AdvanceModel();
  }

  @action
  async initAdvanceListButMaintainFilters() {
    this.advanceList = {
      ...this.advanceList,
      list: [],
      dataSource: [],
      has_more: true,
      total: 0,
      total_disbursement_amount: 0,
      actual_disburse_amount: 0,

      page_index: 0,
      page_size: 15,
      // start_time: defaultStartDate,
      // end_time: defaultEndDate,

      loading: false,
      whateverSendrequest: false,
    };
  }

  @action
  async updateStore(params: AdvanceModel) {
    this.advanceList = {
      ...this.advanceList,
      ...params,
    };
  }

  @action
  async getAdvanceList() {
    const {
      page_size,
      start_time,
      end_time,

      loading,
    } = this.advanceList;
    if (loading) return;

    if (
      start_time &&
      end_time &&
      dayjs(start_time.getTime()).add(7, 'month').set('date', 0).valueOf() < end_time.getTime()
    ) {
      Toast.fail(formatMessage({ id: 'transaction.over_time_range' }));
      this.advanceList = {
        ...this.advanceList,
        list: [],
        dataSource: [],
      };
      return;
    }

    this.advanceList = {
      ...this.advanceList,
      loading: true,
    };

    const params = {
      time_range: {
        start_time: start_time ? dayjs(start_time.getTime()).format('YYYY-MM-DD 00:00:00') : null,
        end_time: end_time ? dayjs(end_time.getTime()).format('YYYY-MM-DD 23:59:59') : null,
      },
      pagination: {
        cursor: 0,
        page_index: 1,
        page_size,
      },
    };
    const { code, result } = await this.post(Advance.API.get_advance_list, {
      body: {
        ...params,
      },
    });
    if (code === 0) {
      const {
        list,
        total_disbursement_amount,
        page_info: { total, has_more, page_size, page_index },
      } = result;
      const newList = list ?? [];
      this.advanceList = {
        ...this.advanceList,
        list: newList,
        dataSource: newList,
        has_more: has_more ?? false,
        total: total ?? 0,
        total_disbursement_amount: total_disbursement_amount ?? 0,
        page_index: page_index ?? this.advanceList.page_index,
        page_size: page_size ?? this.advanceList.page_size,
        loading: false,
      };
    } else {
      this.advanceList = {
        ...this.advanceList,
        loading: false,
      };
    }
  }

  @action
  async loadMoreAdvanceList() {
    const {
      list,
      total_disbursement_amount: originTotalDisbursementAmount,

      page_index,
      page_size,
      start_time,
      end_time,

      loading,
      has_more,
    } = this.advanceList;
    if (loading || !has_more) return;

    this.advanceList = {
      ...this.advanceList,
      loading: true,
    };

    const params = {
      time_range: {
        start_time: start_time ? dayjs(start_time.getTime()).format('YYYY-MM-DD 00:00:00') : null,
        end_time: end_time ? dayjs(end_time.getTime()).format('YYYY-MM-DD 23:59:59') : null,
      },
      pagination: {
        cursor: list[list?.length - 1]?.group_id ?? 0,
        page_index: page_index + 1,
        page_size,
      },
    };
    const { code, result } = await this.post(Advance.API.get_advance_list, {
      body: {
        ...params,
      },
    });
    if (code === 0) {
      const { list: originList } = this.advanceList;
      const {
        list,
        total_disbursement_amount,
        page_info: { total, has_more, page_size, page_index },
      } = result;
      const newList = originList.concat(list ?? []);
      this.advanceList = {
        ...this.advanceList,
        list: newList,
        dataSource: newList,
        has_more: has_more ?? false,
        total: total ?? 0,
        total_disbursement_amount:
          page_index > 1 ? originTotalDisbursementAmount : total_disbursement_amount ?? 0,
        page_index: page_index ?? this.advanceList.page_index,
        page_size: page_size ?? this.advanceList.page_size,
        loading: false,
      };
    } else {
      this.advanceList = {
        ...this.advanceList,
        loading: false,
      };
    }
  }

  @action
  async getAdvanceInfo(group_id: number | string) {
    const { code, result } = await this.post(Advance.API.get_advance_detail, {
      body: { group_id },
    });
    if (code === 0) {
      this.advanceInfo = { ...this.advanceInfo, ...result };
    }
    return result || this.advanceInfo;
  }

  @action
  async getAdvanceOrderList(group_id: number | string) {
    const { page_info, list } = this.infoOrderList;
    if (!page_info.has_more || !this.orderLoaded) return;
    this.orderLoaded = false;

    const { code, result } = await this.post(Advance.API.get_group_settlements, {
      body: {
        group_id,
        pagination: {
          page_index: page_info.page_index + 1,
          page_size: page_info.page_size,
          cursor: list.length > 0 ? list?.[list.length - 1]?.id ?? 0 : 0,
        },
      },
    });
    if (code === 0) {
      const newList = [...this.infoOrderList.list, ...(result.list ?? [])];
      this.infoOrderList = {
        ...this.infoOrderList,
        ...result,
        list: newList,
      };
      this.orderLoaded = true;
    }
    this.orderLoaded = true;
    return result;
  }

  @action
  async initAdvanceInfo() {
    this.advanceInfo = new AdvanceInfoModel();
    this.infoOrderList = new AdvanceInfoOrderListModel();
    this.orderLoaded = true;
  }
}
