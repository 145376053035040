import { BaseService } from '../base';
import { makeObservable, observable, action, autorun, runInAction } from 'mobx';
import { setFullLoadingOnly } from '@/utils/common';

export class GeneralService extends BaseService {
  @observable prevPath: string = '';
  @observable nextPath: string = '';
  @observable pathHistory: string[] = [];

  @observable loading: Record<string, boolean> = {};

  constructor() {
    super();
    makeObservable(this);
    autorun(() => {
      if (this.prevPath !== this.nextPath) {
        setFullLoadingOnly();
        runInAction(() => {
          this.pathHistory.push(this.nextPath);
          this.prevPath = this.nextPath;
        });
      }
    });
  }

  @action
  updatePath(path: string) {
    this.nextPath = path;
  }
}
