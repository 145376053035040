import { Expose } from 'class-transformer';

export class PercentageResponseModel {
  /**
   * @deprecated in refactor
   * disbursement amount
   */
  @Expose()
  current_mortgage_disburse_amount!: number;

  /**
   * 根据当前设置的放款比例计算得到的实际放款金额，扣除了服务费
   */
  @Expose()
  to_be_received_amount!: number;

  /**
   * @deprecated in refactor
   * daily fee
   */
  @Expose()
  current_daily_fee!: number;

  /**
   * daily fee
   * 根据当前设置的放款比例计算的日利息，BR无利息
   */
  @Expose()
  daily_fee!: number;

  /**
   * @deprecated in refactor
   * eligible amount
   */
  @Expose()
  current_total_eligible_amount!: number;

  /**
   * eligible amount
   */
  @Expose()
  total_mortgage_amount!: number;

  /**
   * @deprecated in refactor
   * 计算放款金额时使用的比例，通常为传入比例，当超过风控最大比例时，采用风控最大比例，当放款总额超过可用额度时，为系统调整后比例
   */
  @Expose()
  current_disburse_percentage!: number;

  /**
   * 计算放款金额时使用的比例，通常为传入比例，当超过风控最大比例时，采用风控最大比例，当放款总额超过可用额度时，为系统调整后比例
   */
  @Expose()
  disburse_percentage!: number;

  /**
   * 用户可设置的最大比例
   */
  @Expose()
  percentage_max!: number;

  /**
   * 用户可设置的最小比例，目前默认：10
   */
  @Expose()
  percentage_min!: number;

  /**
   * 用户的利率
   */
  @Expose()
  interest_rate!: number;

  /**
   * @deprecated in refactor
   * 放款金额的比例是否有被调整过（超出了availible limit时的调整）
   */
  @Expose()
  is_adjusted!: boolean;

  /**
   * @deprecated in refactor
   * 是否处于免息期
   */
  @Expose()
  is_in_free_interest_period!: boolean;

  /**
   * 是否处于免息期
   * boolean
   */
  @Expose()
  is_in_free_period!: number;

  /**
   * 免息期长度，例如7天或14天
   */
  @Expose()
  free_interest_promotion_days!: number;

  /**
   * @deprecated in refactor
   * 免息期截止时间 s
   */
  @Expose()
  free_interest_end_timestamp!: number;

  /**
   * 免息结束时间戳，单位秒
   */
  @Expose()
  free_interest_end_time!: number;

  /**
   * 1--新用户激活免息 2--闲置用户免息
   */
  @Expose()
  free_interest_type!: number;

  /**
   * @deprecated in refactor
   * 根据当前设置的放款比例计算得到的anticipate amount，没有扣除服务费的放款金额
   */
  @Expose()
  current_anticipate_amount!: number;

  /**
   * @deprecated in refactor
   * 根据当前设置的放款比例计算得到的amount，没有扣除服务费的放款金额
   */
  @Expose()
  advance_disburse_amount!: number;

  /**
   * 根据当前设置的放款比例计算得到的amount，没有扣除服务费的放款金额
   */
  @Expose()
  principal_amount!: number;

  /**
   * @deprecated in refactor
   * 根据当前设置的放款比例计算得到的服务费
   */
  @Expose()
  current_service_charge!: number;

  /**
   * 根据当前设置的放款比例计算得到的服务费
   */
  @Expose()
  service_charge_amount!: number;

  /**
   * 服务费费率，例如0.10%，返回"0.10"
   */
  @Expose()
  service_charge_rate!: number;

  /**
   * ID特有。用户实际服务费率（加上阶梯费率后）
   */
  @Expose()
  current_service_fee!: number;
  // /**
  //  * 根据当前设置的放款比例计算得到的remain_amount
  //  */
  // @Expose()
  // remain_amount!: number;

  /**
   * 根据当前设置的放款比例计算得到的remain_amount
   */
  @Expose()
  remaining_disburse_amount!: number;

  @Expose()
  discounted_service_charge_amount!: number;
}
