import { loading, Toast } from '@credit/tips';
// import * as Sentry from '@sentry/browser';
import {
  BIZ_TYPE,
  RISK_REJECT_CODE,
  PerfEventType,
  CodeType,
  ExitState,
} from '@/stores/userInfo/userInfo.interface';
import {
  getAppVersion,
  getRnVersion,
  isAndroid,
  isIOS,
  isPC as isPc,
  isSamePath,
  queryParams,
  queryStringify,
  sleep,
  switchKeys,
  toCamelCase,
  toSnakeCase,
  typeCheck as tc,
} from '@credit/utils';
import { history } from 'umi';
import { formatMessage as t } from '@/utils/locale';
import appInfo from '@/stores/appInfo';
// import bridge from './bridge';
import Currency from './currency';
import Time, { formatType } from './time';
import config from '@/config';
import { stores } from '@/stores/useStore';
import insight from './v2/insight';

export interface IMoney {
  currency_code?: string;
  value: string;
}

export {
  queryParams,
  queryStringify,
  isPc,
  isIOS,
  sleep,
  isAndroid,
  getAppVersion,
  getRnVersion,
  isSamePath,
  switchKeys,
  toCamelCase,
  toSnakeCase,
};

export const getDueDate = (timestamp: number | Date, showTime = false) => {
  const dateStr = formatType.format4;
  const timeStr = `${dateStr} hh:mm`;
  return Time.format(timestamp, showTime ? timeStr : dateStr);
};

export const formatDate = Time.format;

// 下划线转驼峰
export const toHump = toCamelCase;

// 驼峰转下划线
export const toLine = toSnakeCase;

/**
 * 格式化钱（为数字加千分位点）
 * @params money 可能为money struct，针对money struct做兼容处理
 */
export const toThousands = (
  money: number | string | IMoney,
  opts: {
    showSymbol?: boolean; // 是否展示国家的货币符合，默认不展示
    showDecimal?: boolean; // 设置成 false 的时候不展示小数部分，不会四舍五入
    precision?: number; // 设置成 0，不展示小数部分，自动四舍五入
    separator?: string; // 整数的分隔符
  } = {},
): string => {
  const m = tc.isObject<IMoney>(money) ? Number(money.value) : Number(money);
  return Currency.format(m, opts);
};

// 跳转到home页
export const goHome = () => {
  history.push('/home');
};

// // 关闭webview
// export const closeWebview = () => {
//   bridge.call('popWebView', {});
// };

// export const goEntry = () => {
//   closeWebview();
// };

// 默认跳转，后退到上一页面
export const goBack = () => {
  history.goBack();
};

export function getAppState(name: string): any {
  const nameList = name.split('.');
  let result = stores;
  nameList.forEach((key) => {
    if (result) {
      result = (result as any)?.[key];
    }
  });
  return result;
}

export const showFullLoading = loading.showFullLoading;

export const hideFullLoading = loading.hideFullLoading;

export const setFullLoadingOnly = loading.setFullLoadingOnly;

export const hideAllFullLoading = loading.hideAllFullLoading;

const genLoginUrl = (redirectUrl: string) => {
  // 因为WSA会对redirect_url进行校验，所以新增region域名时需要找duanzy@sea.com , shaobo.gan@shopee.com 添加域名白名单。
  let env = REACT_APP_ENV;
  env = ['uat', 'live'].indexOf(env) >= 0 ? env : 'test';
  const url = new URL(
    `https://${env !== 'live' ? `${env}.` : ''}${BRIDGE_HOST}/authenticate/oauth`,
  );
  // 设置使用场景为shopee app 的 webview
  url?.searchParams?.append('__mobile__', '1');
  // 设置使用场景为shopee app 的 version
  url?.searchParams?.append('version', '2');
  // state 是自己定义的一个参数
  url?.searchParams?.append('state', 'fastescrow');
  // 设置client_id参数, credit 团队给的client_id为4
  url?.searchParams?.append('client_id', '4');
  // 设置redirectUrl参数
  url?.searchParams?.append('redirect_uri', redirectUrl);
  return url?.href;
};

export const goWsaUrl = () => {
  let url = window.location.href;
  if (url.indexOf('?') > -1) {
    url = url.substring(0, url.indexOf('?'));
  }
  const offlineQuery = window._offline_build_url_query;
  // 兼容离线包
  if (offlineQuery) {
    url = `${url}index.html?${offlineQuery}`;
  }
  // 回跳的url形如 'https://cashloan.test.shopeekredit.co.id/activate?code=xxx&&errcode=0&errmsg=OK';
  const loginUrl = genLoginUrl(url);
  // console.log(loginUrl);

  insight.loginReport({
    status: 'call',
    type: 'nonce_code',
  });

  window.location.href = loginUrl;
};

// 延迟
const delay = (time: number) => new Promise((resolve) => setTimeout(resolve, time));

// /**
//  * 尝试获取authCode，按time进行重试的次数。
//  * @param time
//  */
// const getAuthCodeByRetryTimes = async (time: number) => {
//   let retryTime: number = time <= 0 ? 1 : time;

//   while (retryTime > 0) {
//     --retryTime;
//     // eslint-disable-next-line no-await-in-loop
//     const authCode = await bridge.getAuthCode();

//     console.log(authCode, 'authCode...');

//     insight.bridgeReport({
//       name: 'requestAuthCodeFromCoreAuth',
//       status: authCode ? 'success' : 'error',
//       msg: `retryTime: ${retryTime}`,
//     });

//     if (authCode) {
//       return authCode;
//     }

//     // 加200毫秒的延迟
//     // eslint-disable-next-line no-await-in-loop
//     await delay(200);
//   }

//   return '';
// };

/**
 * 获取auth code
 */
// export async function getAuthCode(): Promise<{
//   errorCode: number;
//   token: string;
//   type: CodeType;
// }> {
//   const urlParams = queryParams();

//   let nonceCode: string = urlParams.nonce;
//   if (appInfo.nonceCode === nonceCode) {
//     // url带了noncecode参数，只能用一次。
//     nonceCode = '';
//   }

//   if (nonceCode) {
//     appInfo.nonceCode = nonceCode;
//     return {
//       errorCode: 0,
//       token: nonceCode,
//       type: CodeType.OnceCode,
//     };
//   }

//   if (urlParams?.state === 'fastescrow' && urlParams?.exit_state !== ExitState.Success) {
//     // get wsa code fail。1告诉调用方，不用再重试，避免死循环
//     bridge.call('trackPerformanceEvent', {
//       subtype: PerfEventType.getAuthCodeFail,
//       payload: {
//         fail_type: 1,
//         err_code: urlParams?.exit_state,
//       },
//     });

//     return {
//       errorCode: 1,
//       token: '',
//       type: CodeType.OnceCode,
//     };
//   }

//   // mock环境对authcode无要求，dev环境在PC模拟器上也没有要求
//   if (REACT_APP_ENV === 'mock') {
//     // return [0, 'mock'];
//     return {
//       errorCode: 0,
//       token: 'mock',
//       type: CodeType.AuthCode,
//     };
//   }

//   insight.loginReport({
//     status: 'call',
//     type: 'auth_code',
//   });

//   // 前两步处理从wsa网站重定向回来；下面的代码处理一进来的情况
//   const authCode = await getAuthCodeByRetryTimes(3);

//   // if (!authCode) {
//   //   Sentry.captureMessage('BRIDGE_AUTHCODE_ERR', {
//   //     extra: {
//   //       url: window.location.href,
//   //       user: getAppState('userInfo.userInfo') ?? {},
//   //     },
//   //   });
//   // }

//   // 如果为''，0告诉调用方，要用wsa url兜底
//   return {
//     errorCode: 0,
//     token: authCode,
//     type: CodeType.AuthCode,
//   };
// }

/**
 * map all keys to values
 *   e.g. {a: 1, b: null} => {a: "a", b: "b"}
 * @param obj keys source
 */
export function keyMirror<T extends object, K extends keyof T>(obj: T): Record<K, string> {
  return Object.keys(obj).reduce((memo, key) => {
    memo[key as K] = key;
    return memo;
  }, {} as Record<K, string>);
}

/**
 * app@2.70 以上才可用 finance rn
 */
export const isFinanceCircleRnAvailable = () => {
  const appVer = Number(getAppVersion()).toString().slice(0, 2);

  return Number(appVer) >= 27;
};

// export function goToFinanceEntry() {
//   // 印尼上线了RN版本的finance circle 且 H5版本finance Entry在cashloan产品下。
//   if (BIZ_COUNTRY === 'id') {
//     if (isFinanceCircleRnAvailable()) {
//       bridge.call('navigateAppPath', {
//         path: 'rn/@shopee-rn/finance-circle/HOME',
//         popSelf: true,
//       });
//     } else {
//       bridge.callWebview(`${config.cashloanDomain}/entry`, undefined, 1);
//     }
//   } else {
//     bridge.callWebview(`${config.financeDomain}`, undefined, 1);
//   }
// }

// export function goToSellerWallet() {
//   bridge.call('navigateAppRL', {
//     apprl: '/rn/@shopee-rn/seller-payment/WALLET',
//     params: {
//       // selfDismiss: true,
//     },
//   });
// }

export const getActivateRejectCodeDesc = (params: {
  riskRejectCode: RISK_REJECT_CODE;
  coolDownEndTime: number;
}) => {
  const { riskRejectCode, coolDownEndTime } = params;
  let desc: string;
  let btnText: string;
  switch (riskRejectCode) {
    case RISK_REJECT_CODE.FRAUD_COOL_DOWN1:
    case RISK_REJECT_CODE.FRAUD_COOL_DOWN2:
      desc = t(
        { id: 'result.reject.fraud_cool_down' },
        {
          date: Time.format(coolDownEndTime, formatType.format4),
        },
      );
      btnText = t({ id: 'result.reject.check_other_loan' });
      break;
    case RISK_REJECT_CODE.UNDERWRITING:
      desc = t(
        { id: 'result.reject.underwriting' },
        {
          date: Time.format(coolDownEndTime, formatType.format4),
        },
      );
      btnText = t({ id: 'result.reject.check_other_loan' });
      break;
    case RISK_REJECT_CODE.VERIFICATION_COOL_DOWN:
      desc = t(
        { id: 'result.reject.verify_cool_down' },
        {
          date: Time.format(coolDownEndTime, formatType.format4),
        },
      );
      btnText = t({ id: 'result.reject.check_other_loan' });
      break;
    case RISK_REJECT_CODE.FRAUD_BAN_APP1:
    case RISK_REJECT_CODE.FRAUD_BAN_APP2:
      desc = t({ id: 'result.reject.fraud_ban_app' });
      btnText = t({ id: 'result.reject.check_other_loan' });
      break;
    case RISK_REJECT_CODE.OVERDUE:
      desc = t({ id: 'result.reject.overdue' });
      btnText = t({ id: 'result.reject.pay_now' });
      break;
    case RISK_REJECT_CODE.IC_PHOTO_OR_NOT_QUALIFIED:
      desc = t({ id: 'result.reject.ic_photo_error' });
      btnText = t({ id: 'result.reject.retry_activation' });
      break;
    case RISK_REJECT_CODE.IC_INVALIDED:
      desc = t({ id: 'result.reject.ic_invalided' });
      btnText = t({ id: 'result.reject.retry_activation' });
      break;
    case RISK_REJECT_CODE.KYC_NOT_MATCH:
      desc = t({ id: 'result.reject.kyc_error' });
      btnText = t({ id: 'result.reject.retry_activation' });
      break;
    case RISK_REJECT_CODE.NO_COOL_TIME1:
    case RISK_REJECT_CODE.NO_COOL_TIME2:
    case RISK_REJECT_CODE.UNDEFINED:
      desc = t({ id: 'result.reject.no_cool_time' });
      btnText = t({ id: 'result.reject.check_other_loan' });
      break;
    case RISK_REJECT_CODE.SYSTEM_ISSUE:
    default:
      desc = t({ id: 'result.reject.system_error' });
      btnText = t({ id: 'result.reject.retry_activation' });
      break;
  }
  return {
    desc,
    btnText,
  };
};
/**
 * 创建一个特定位数，且每个元素值相同的数组
 * @param {*} count
 * @param {*} value
 */
export function createArray(value: any, count: number = 1) {
  // @ts-ignore
  return [].fill.call(new Array(count), value);
}

/**
 * 对字符串向右补位
 * @param {*} str
 * @param {*} len
 * @param {*} pad
 */
export const padRight = (str: string, len: number, pad: string): string => {
  if (str.length >= len) {
    return str;
  }

  const diff = len - str.length;
  const letters = createArray(pad, diff);

  return str + letters.join('');
};

export const atLest2Decimals = (value: string) => {
  if (tc.isNumber(Number(value))) {
    const str = value?.toString();
    const subStrArr = str.split('.');
    const decimalStr = subStrArr[1] ?? '';
    if (decimalStr.length < 2) {
      subStrArr[1] = padRight(decimalStr, 2, '0');
    }
    return subStrArr.join('.');
  }
  return value;
};

export const isValuableArray = (list: any) => {
  return Array.isArray(list) && list.length > 0;
};

// 默认跳转，后退到上一页面
// export const goBackOrClose = () => {
//   history.goBack();
//   const lastHref = window.location.href;
//   setTimeout(() => {
//     if (lastHref === window.location.href) {
//       closeWebview();
//     }
//   }, 300);
// };

// 自定义弹窗禁用RN顶部导航Nav bar
// export const disabledNavBar = (visible: boolean) => {
//   bridge.call('configurePage', {
//     config: {
//       disableSwipeBack: visible ? 1 : 0,
//       disableReload: 1,
//     },
//   });
//   // @ts-ignore
//   bridge.call('dimNavbar', {
//     isDim: !!visible,
//     color: '000000',
//     alpha: 0.41,
//   });
// };

export const BizCommon = {
  biz_type: BIZ_TYPE.FastEscrow,
  biz_country: BIZ_COUNTRY,
};

// cashloan copy过来的图片下载api
// export const saveImg = (downloadUrl: string, fileName: string) => {
//   if (isAndroid()) {
//     // 安卓 saveImage API无法携带cookie, 没有修复,改成downloadFile API
//     // downloadFile 文档: https://confluence.shopee.io/pages/viewpage.action?pageId=31438459
//     return bridge.call('downloadFile' as any, {
//       url: downloadUrl,
//       fileName,
//     });
//   }
//   // IOS 使用bridge saveImage 正常 注意saveImage自带Toast
//   return bridge.call('saveImage', {
//     imageUrl: downloadUrl,
//     filename: fileName,
//   });
// };

// 保存图片方法
interface SaveImageOption {
  url: string;
  filename: string;
  popupText: string;
  successText: string;
  onSuccess?: () => void;
}
// export const saveImage = (opt: SaveImageOption) => {
//   const { url, filename, successText, popupText, onSuccess } = opt;
//   bridge
//     .call('requestAppPermission', {
//       permissionList: ['gallery'],
//       popupText,
//     })
//     .then((res: any) => {
//       console.log('requestAppPermission', res);
//       // bridge 统一用1表示成功
//       if (res.status === 1) {
//         // 安卓的saveImage带不了cookie，只能用downloadFile API
//         if (isAndroid()) {
//           // iOS会自动校验是否开启了权限，安卓需要根据checkAppPermission判断用户是否开启了指定相册权限
//           bridge
//             .call('checkAppPermission', {
//               permissionList: ['gallery'],
//             })
//             .then((response: any) => {
//               console.log('checkAppPermission', response);
//               console.log(
//                 'checkAppPermission authorizationStatuses',
//                 response?.data?.authorizationStatuses,
//               );
//               // authorizationStatuses Array of "UInteger":
//               //  - 0: Not determined
//               //  - 1: Granted
//               //  - 2: Denied
//               //  - 3: Not supported
//               if (response?.data?.authorizationStatuses?.[0] === 1) {
//                 Toast.loading();
//                 bridge
//                   .call('downloadFile', {
//                     url,
//                     fileName: filename,
//                   })
//                   .then((resp: any) => {
//                     if (resp.error === 0) {
//                       console.log('downloadFile', resp);
//                       Toast.success(successText);
//                       onSuccess?.();
//                     } else {
//                       Toast.fail('Error');
//                     }
//                   });
//               } else {
//                 Toast.fail(popupText);
//               }
//             });
//         } else {
//           // IOS 使用bridge saveImage 正常 注意saveImage自带Toast
//           bridge.call('saveImage', {
//             imageUrl: url,
//             filename,
//           });
//         }
//       }
//     });
// };

// 保存pdf格式文件
// export const savePdf = (opt: SaveImageOption) => {
//   const { url, filename } = opt;
//   // https://confluence.shopee.io/pages/viewpage.action?pageId=31438459

//   bridge
//     .call('previewFile', {
//       url,
//       fileName: filename,
//     })
//     .then((res) => {
//       console.log('previewFile', res);
//     });
// };

export const getFileNameByPath = (path?: string) => {
  const tempArr = path?.split('/');
  if (tempArr) {
    return tempArr[tempArr.length - 1];
  }
  return path;
};
