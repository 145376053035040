namespace Upcoming {
  export enum API {
    'get_user_candidate_orders_summary' = '/fast-escrow/order/get_user_candidate_orders_summary',
  }

  export enum RefactorAPI {
    'get_upcoming' = '/fast-escrow/transaction/query_upcoming_info',
  }
}

export default Upcoming;
