import { makeObservable, action, observable, runInAction } from 'mobx';
import { BaseService } from '../base';
import Home from './api.interface';
import { HomeModel } from './home.model';
import { UC_OTP_SCENE, UC_TOGGLE_STATUS } from '../userInfo/userInfo.interface';

export class CommonService extends BaseService {
  @observable home: HomeModel = new HomeModel();

  constructor() {
    super();
    makeObservable(this);
  }

  @action
  async getFEProductConfigs() {
    const res = await this.post(Home.RefactorAPI.get_fe_product_config);
    return res;
  }

  @action
  async getHomeInfo(): Promise<HomeModel> {
    const { code, result } = await this.post(Home.API.get_homepage);
    if (code === 0) {
      runInAction(() => {
        this.home = {
          ...this.home,
          ...result,
        };
      });
    }
    return result || this.home;
  }

  @action
  async updateUserConfig(params: {
    toggle_status?: UC_TOGGLE_STATUS;
    disburse_percentage?: number;
    fee_change_type?: number;
  }) {
    return await this.post(Home.API.update_user_config, {
      body: params,
    });
  }

  @action
  async updateFirstActivate() {
    return await this.post(Home.API.update_first_activate);
  }

  @action
  async getLatestDisbursedGroups() {
    return await this.post(Home.API.get_user_latest_disbursed_groups);
  }

  @action
  async getUserMobile(scene: UC_OTP_SCENE) {
    return await this.post(Home.API.get_user_mobile_number, {
      body: { otp_scene: scene },
    });
  }

  /**
   *
   * 单独更新设置的比例，也可以请求方式getHomeInfo来更新
   * @param {number} [percentage=0]
   * @memberof HomeService
   */
  @action
  async updatePercentage(percentage: number = 0) {
    this.home = {
      ...this.home,
      user_percentage: percentage,
    };
  }
}
