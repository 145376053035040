import { Time, typeCheck, cal } from '@credit/utils';
import { formatMessage } from './locale';
import { locale } from './locale';

let region: Region = BIZ_COUNTRY as Region;

switch (BIZ_COUNTRY) {
  case 'id':
  case 'th':
  case 'br':
  case 'my':
  case 'ph':
    region = BIZ_COUNTRY;
    break;
  default:
    console.error('请设置region逻辑');
    break;
}

const months = [
  formatMessage({ id: 'month1' }),
  formatMessage({ id: 'month2' }),
  formatMessage({ id: 'month3' }),
  formatMessage({ id: 'month4' }),
  formatMessage({ id: 'month5' }),
  formatMessage({ id: 'month6' }),
  formatMessage({ id: 'month7' }),
  formatMessage({ id: 'month8' }),
  formatMessage({ id: 'month9' }),
  formatMessage({ id: 'month10' }),
  formatMessage({ id: 'month11' }),
  formatMessage({ id: 'month12' }),
];

Time.config({
  region,
  accuracy: 1000,
  months,
});

export const formatType = (function () {
  if (locale === 'zh') {
    return {
      format1: 'ETDD日hh:mm',
      format2: 'hh:mm',
      format3: 'ETDD日',
      format4: 'YYYY年ETDD日',
    };
  }
  return {
    format1: 'DD ET hh:mm',
    format2: 'hh:mm',
    format3: 'DD ET',
    format4: 'DD ET YYYY',
  };
})();

export default Time;

export const formatWithoutTimeZone = (
  timestamp: number | string | Date,
  format: string = 'YYYY/MM/DD hh:mm:ss',
  otps: {
    accuracy?: number; // 精度
  } = {},
) => {
  if (!timestamp) {
    return '';
  }
  let time;
  switch (true) {
    case typeCheck.isDate(timestamp):
      time = (timestamp as any).getTime();
      break;
    case typeCheck.isNumber(timestamp):
      time = cal.times(timestamp as number, otps?.accuracy ?? 1);
      break;
    default:
      time = new Date(timestamp).getTime();
  }
  const date = new Date(time);
  if (!typeCheck.isDate(date)) {
    return '';
  }
  const opt: any = {
    'Y+': date.getFullYear().toString(), // 年
    'M+': (date.getMonth() + 1).toString(), // 月
    'D+': date.getDate().toString(), // 日
    'h+': date.getHours().toString(), // 时
    'H+': date.getHours().toString(), // 历史原因不做 24 小时区分
    'm+': date.getMinutes().toString(), // 分
    's+': date.getSeconds().toString(), // 秒
    ET: months[date.getMonth()],
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  let ret: any;
  let formated: string = format;
  Object.keys(opt).forEach((k: string) => {
    ret = new RegExp(`(${k})`).exec(formated);
    if (ret) {
      formated = formated.replace(
        ret[1],
        ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'),
      );
    }
  });
  return formated;
};
