namespace Repayment {
  export enum API {
    'get_repay_info' = '/fast-escrow/transaction/query_proactive_info',
    'get_repay_channel' = '/fast-escrow/transaction/query_repay_channel',
    'proactive_repay' = '/fast-escrow/transaction/proactive_repayment',
    'get_repay_result' = '/fast-escrow/transaction/get_proactive_order_detail',
  }
}

export default Repayment;
