import { makeObservable, override } from 'mobx';
import { CommonService } from './common';
import Percentage from './interface';

export class PercentageService extends CommonService {
  constructor() {
    super();
    makeObservable(this);
  }

  @override
  async getPercentageInfo(current_user_percentage: number) {
    const { code, result } = await this.post(
      Percentage.RefactorAPI.get_edit_disburse_percentage_info,
      {
        body: { current_user_percentage },
      },
    );
    if (code === 0) {
      this.percentageState = {
        ...this.percentageState,
        ...result,
      };
    }
  }
}
