import * as dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
import timeZone from 'dayjs/plugin/timezone';
import { locale } from '@/utils/locale';

// 设置语言, 默认英文
const language = locale || 'en';
if (locale === 'my') {
  require(`dayjs/locale/ms-my`);
  dayjs.locale('ms-my');
} else {
  // eslint-disable-next-line import/no-dynamic-require
  require(`dayjs/locale/${language}`);
  dayjs.locale(language);
}

// 设置时区
dayjs.extend(utc);
dayjs.extend(timeZone);
// 先设置为用户当前时区
dayjs.tz.setDefault(dayjs.tz.guess());
// 固定为app语言所在的时区:
// dayjs.tz.setDefault('America/Toronto');

// 加载高级格式插件
dayjs.extend(advancedFormat);

export const DateFormat = 'DD MMM YYYY';
export const TimeFormat = 'DD MMM YYYY hh:mm';

export const getShowDate = (timestamp: number, format?: string) => {
  // 需要传入秒级时间戳, 默认DD MMM YYYY
  const formation = format || 'DD MMM YYYY';
  return dayjs.unix(timestamp).format(formation);
};

export default dayjs;
