import { Toast } from '@credit/tips';
import { makeObservable, action, observable } from 'mobx';
import { BaseService } from '../base';
import dayjs from '@/utils/dayjs';
import { formatMessage } from '@/utils/locale';
import { defaultStartDate, defaultEndDate } from '@/stores/transaction/const';
import Order from './api.interface';
import { ORDER_STATUS } from './order.interface';
import { OrderModel, OrderInfo } from './order.model';

export class CommonService extends BaseService {
  @observable orderList: OrderModel = new OrderModel();
  @observable orderInfo: OrderInfo = new OrderInfo();

  constructor() {
    super();
    makeObservable(this);
  }

  @action
  async initOrderList() {
    this.orderList = {
      list: [],
      dataSource: [],
      has_more: true,
      total: 0,
      total_amount: 0,

      page_index: 0,
      page_size: 15,
      start_time: defaultStartDate,
      end_time: defaultEndDate,
      status: ORDER_STATUS.ALL,

      loading: false,
      whateverSendrequest: false,
      page_info: {
        total: 0,
        page_index: 0,
        page_size: 15,
        has_more: true,
      },
      total_mortgage_amount: 0,
      total_repayment_amount: 0,
      total_outstanding_amount: 0,
    };
  }

  @action
  async initOrderListButMaintainFilters() {
    this.orderList = {
      ...this.orderList,
      list: [],
      dataSource: [],
      has_more: true,
      total: 0,
      total_amount: 0,

      page_index: 0,
      page_size: 15,
      // start_time: defaultStartDate,
      // end_time: defaultEndDate,
      // status: ORDER_STATUS.ALL,

      loading: false,
      whateverSendrequest: false,
    };
  }

  @action
  async updateStore(params: OrderModel) {
    this.orderList = {
      ...this.orderList,
      ...params,
    };
  }

  @action
  async getOrderList() {
    const {
      page_size,
      start_time,
      end_time,
      status,

      loading,
    } = this.orderList;
    if (loading) return;

    if (
      start_time &&
      end_time &&
      dayjs(start_time.getTime()).add(7, 'month').set('date', 0).valueOf() < end_time.getTime()
    ) {
      Toast.fail(formatMessage({ id: 'transaction.over_time_range' }));
      this.orderList = {
        ...this.orderList,
        list: [],
        dataSource: [],
      };
      return;
    }

    this.orderList = {
      ...this.orderList,
      loading: true,
    };

    const params = {
      time_range: {
        start_time: start_time ? dayjs(start_time.getTime()).format('YYYY-MM-DD 00:00:00') : null,
        end_time: end_time ? dayjs(end_time.getTime()).format('YYYY-MM-DD 23:59:59') : null,
      },
      pagination: {
        cursor: 0,
        page_index: 1,
        page_size,
      },
      status,
    };
    const { code, result } = await this.post(Order.API.get_order_list, {
      body: {
        ...params,
      },
    });
    if (code === 0) {
      const {
        list,
        total_amount,
        page_info: { total, has_more, page_size, page_index },
      } = result;
      const newList = list ?? [];
      this.orderList = {
        ...this.orderList,
        list: newList,
        dataSource: newList,
        has_more: has_more ?? false,
        total: total ?? 0,
        total_amount: total_amount ?? 0,
        page_index: page_index ?? this.orderList.page_index,
        page_size: page_size ?? this.orderList.page_size,
        loading: false,
      };
    } else {
      this.orderList = {
        ...this.orderList,
        loading: false,
      };
    }
  }

  @action
  async loadMoreOrderList() {
    const {
      list,
      total_amount: originTotalAmount,

      page_index,
      page_size,
      start_time,
      end_time,
      status,

      loading,
      has_more,
    } = this.orderList;
    if (loading || !has_more) return;

    this.orderList = {
      ...this.orderList,
      loading: true,
    };

    const params = {
      time_range: {
        start_time: start_time ? dayjs(start_time.getTime()).format('YYYY-MM-DD 00:00:00') : null,
        end_time: end_time ? dayjs(end_time.getTime()).format('YYYY-MM-DD 23:59:59') : null,
      },
      pagination: {
        cursor: list[list?.length - 1]?.id ?? 0,
        page_index: page_index + 1,
        page_size,
      },
      status,
    };
    const { code, result } = await this.post(Order.API.get_order_list, {
      body: {
        ...params,
      },
    });
    if (code === 0) {
      const { list: originList } = this.orderList;
      const {
        list,
        total_amount,
        page_info: { total, has_more, page_size, page_index },
      } = result;
      const newList = originList.concat(list ?? []);
      this.orderList = {
        ...this.orderList,
        list: newList,
        dataSource: newList,
        has_more: has_more ?? false,
        total: total ?? 0,
        total_amount: page_index > 1 ? originTotalAmount : total_amount ?? 0,
        page_index: page_index ?? this.orderList.page_index,
        page_size: page_size ?? this.orderList.page_size,
        loading: false,
      };
    } else {
      this.orderList = {
        ...this.orderList,
        loading: false,
      };
    }
  }

  @action
  async getOrderInfo(settle_id: string) {
    const { code, result } = await this.post(Order.API.get_order_detail, {
      body: { settle_id },
    });
    if (code === 0) {
      this.orderInfo = { ...this.orderInfo, ...result };
    }
    return result || this.orderInfo;
  }

  @action
  initOrderInfo() {
    this.orderInfo = {
      total_amount: 0,
      settle_status: 0,
      is_overdue: false,
      outstanding_amount: 0,
      order_sn: '',
      order_id: 0,
      order_link: '',
      partially_repaid_amount: 0,
      charge_mode: 2,
      principal_amount: 0,
      actual_disburse_amount: 0,
      is_received_settle_notify: false,
      remaining_disburse_amount: '0',
      interest_rate: '',
      repay_time: 0,
      actual_advance_time: 0,
      interest: 0,
      service_charge_amount: 0,
      remaining_disburse_time: 0,
      disburse_percentage: 0,
      clear_time: 0,
      order_income: 0,
      entity_id: '',
      repayment_amount: '0',
      interest_amount: 0,
      last_transaction_time: 0,
      fdd_amount: '0',
      fdd_deduct_time: 0,
      total_deduction_amount: '0',
      last_auto_deduct_time: 0,
      proactive_repayment_amount: '0',
      proactive_repayment_time: 0,
      mortgage_amount: '0',
      settlement_amount: '0',
      total_received_amount: '0',
      deduction: '0',
    };
  }
}
