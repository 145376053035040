namespace UserInfo {
  export enum API {
    'login' = '/fast-escrow/usercenter/bff/generate_user_login_token',
    'get_user_info' = '/fast-escrow/usercenter/bff/get_init_user_info_for_credit',
    'get_dr_status' = '/fast-escrow/order/get_dr_status',
    'get_migrate_status' = '/fast-escrow/migration/query_user_to_migrate_status',
    'insight_fe_chat' = '/credit/insight/fe_chat', // 监控接口, 打到网关
  }
}

export default UserInfo;
