// https://npm.garenanow.com/-/web/detail/@shopee_common/currency
import { getUtilsByCountry } from '@shopee_common/currency';

type CurrencyCode = 'SGD' | 'IDR' | 'MYR' | 'PHP' | 'TWD' | 'THB' | 'VND' | 'BRL';
type RegionCode = 'SG' | 'MY' | 'TH' | 'ID' | 'PH' | 'VN' | 'TW' | 'BR';
type Region = 'sg' | 'my' | 'th' | 'id' | 'ph' | 'vn' | 'tw' | 'br';

interface CurrencyConfig {
  code: CurrencyCode;
  symbol: string;
  precision: number;
  maxPrecision?: number;
  separator: string;
  decimalPoint: string;
}

interface CurrencyOptions {
  negative?: boolean; // 强制数字格式化后是正／负数
  serverSidePrice?: boolean; // 处理coreserver直接传过来的数字，这些数字需要被除以100000才是真实数据
  symbol?: string; // 修改在数字前加的货币符号
  html?: boolean; // 是否返回格式为html,设为true，会将货币符号、整数、小数点、小数分割并放进不同的span中返回
  precision?: number; // 小数位数。设置了precision,会强制所有的output都会带precision位的小数点
  maxPrecision?: number; // maxPrecision觉得小数点后最多有多少位
  decimalPoint?: string; // 数字小数点符号
  noSeparator?: boolean; // 整数部分分隔符。如果为true，则为空
}

interface ICurrencyOptions extends CurrencyOptions {
  code?: CurrencyCode;
  showSymbol?: boolean;
  showDecimal?: boolean;
  separator?: string;
}

const configMap: Record<Region, CurrencyConfig> = {
  sg: {
    code: 'SGD',
    symbol: '$',
    precision: 2,
    separator: ',',
    decimalPoint: '.',
  },
  id: {
    code: 'IDR',
    symbol: 'Rp',
    precision: 0,
    separator: '.',
    decimalPoint: ',',
  },
  my: {
    code: 'MYR',
    symbol: 'RM',
    precision: 2,
    maxPrecision: 2,
    separator: ',',
    decimalPoint: '.',
  },
  ph: {
    code: 'PHP',
    symbol: '₱',
    precision: 2,
    maxPrecision: 2,
    separator: ',',
    decimalPoint: '.',
  },
  tw: {
    code: 'TWD',
    symbol: '$',
    precision: 0,
    separator: ',',
    decimalPoint: '.',
  },
  th: {
    code: 'THB',
    symbol: '฿',
    precision: 0,
    maxPrecision: 2,
    separator: ',',
    decimalPoint: '.',
  },
  vn: {
    code: 'VND',
    symbol: '₫',
    precision: 0,
    separator: '.',
    decimalPoint: ',',
  },
  br: {
    code: 'BRL',
    symbol: 'R$',
    precision: 2,
    separator: '.',
    decimalPoint: ',',
  },
};

class Currency {
  private _region: Region;
  private _config: ICurrencyOptions;

  constructor() {
    this._region = BIZ_COUNTRY;
    this._config = configMap[BIZ_COUNTRY];
  }

  getConfig() {
    return this._config;
  }

  getRegion() {
    return this._region;
  }

  getSymbol() {
    return this._config.symbol;
  }

  getSeparator() {
    return this._config.separator;
  }

  getDecimalPoint() {
    return this._config.decimalPoint;
  }

  format(value: number | string, options: ICurrencyOptions = {}) {
    const mergedOptions: ICurrencyOptions = {
      ...(this._config || {}),
      ...options,
    };
    const { formatCurrency } = getUtilsByCountry(this._region.toUpperCase() as RegionCode);
    return formatCurrency(value as any, mergedOptions as CurrencyOptions);
  }
}

const currency = new Currency();

export const symbol = currency.getSymbol();

export default currency;
