// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/workspace/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/loading/index.tsx';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts' */'@/layouts'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__index' */'@/pages/index'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/components/permission'), loading: LoadingComponent})],
        "exact": true
      },
      {
        "path": "/home",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Home__my' */'@/pages/Home/my'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/components/permission'), loading: LoadingComponent})],
        "showLoading": true,
        "exact": true
      },
      {
        "path": "/limitAdjustDetail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__LimitAdjustDetail' */'@/pages/LimitAdjustDetail'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/components/permission'), loading: LoadingComponent})],
        "exact": true
      },
      {
        "path": "/transaction",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Transaction__my' */'@/pages/Transaction/my'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/components/permission'), loading: LoadingComponent})],
        "showLoading": true,
        "exact": true
      },
      {
        "path": "/advance/info",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Transaction__my__advanceInfo__info' */'@/pages/Transaction/my/advanceInfo/info'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/components/permission'), loading: LoadingComponent})],
        "showLoading": true,
        "exact": true
      },
      {
        "path": "/order/info",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Transaction__my__orderInfo__info' */'@/pages/Transaction/my/orderInfo/info'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/components/permission'), loading: LoadingComponent})],
        "showLoading": true,
        "exact": true
      },
      {
        "path": "/setting",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Setting' */'@/pages/Setting'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/components/permission'), loading: LoadingComponent})],
        "showLoading": true,
        "exact": true
      },
      {
        "path": "/eInvoiceSetting",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EInvoicesSetting__my' */'@/pages/EInvoicesSetting/my'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/components/permission'), loading: LoadingComponent})],
        "showLoading": true,
        "exact": true
      },
      {
        "path": "/upcoming",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Upcoming' */'@/pages/Upcoming'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/components/permission'), loading: LoadingComponent})],
        "showLoading": true,
        "exact": true
      },
      {
        "path": "/percentage",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Percentage' */'@/pages/Percentage'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/components/permission'), loading: LoadingComponent})],
        "showLoading": true,
        "exact": true
      },
      {
        "path": "/e-invoice",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Einvoices__my' */'@/pages/Einvoices/my'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/components/permission'), loading: LoadingComponent})],
        "exact": true
      },
      {
        "path": "/billing/repayment",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Repayment__my' */'@/pages/Repayment/my'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/components/permission'), loading: LoadingComponent})],
        "exact": true
      },
      {
        "path": "/billing/repay-result/:repayId",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__RepayResult__my' */'@/pages/RepayResult/my'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/components/permission'), loading: LoadingComponent})],
        "exact": true
      },
      {
        "path": "/maintenance",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Maintenance' */'@/pages/Maintenance'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/exception",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Exception' */'@/pages/Exception'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'@/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
