import qs from 'querystringify';

export class BaseUtils {
  /**
   * 返回当前设备的系统信息
   * @returns 'ios' | 'android' | 'pc'
   */
  getDeviceSystem(): 'ios' | 'android' | 'pc' {
    if (this.isAndroid()) {
      return 'android';
    }

    if (this.isIOS()) {
      return 'ios';
    }

    return 'pc';
  }

  /**
   * 是否是ios系统
   * @returns boolean
   */
  isIOS(): boolean {
    const u = window.navigator.userAgent;
    return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  }

  /**
   * 是否是android系统
   * @returns boolean
   */
  isAndroid(): boolean {
    const u = window.navigator.userAgent;
    return u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
  }

  /**
   * 是否是pc
   * @returns boolean
   */
  isPC(): boolean {
    return !window.navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
    );
  }

  /**
   * 获取app的版本
   * @returns number
   */
  getAppVersion(): number {
    const appver = window.navigator.userAgent.match(/appver=(\d+)/);
    if (appver && appver[1]) {
      return parseInt(appver[1], 10);
    }
    return 0;
  }

  /**
   * 获取rn的版本
   * @returns number
   */
  getRnVersion(): number {
    const rnver = window.navigator.userAgent.match(/rnver=(\d+)/);
    if (rnver && rnver[1]) {
      return parseInt(rnver[1], 10);
    }
    return 0;
  }

  /**
   * 获取shopee_rn_bundle_version的版本
   * @returns number
   */
  getShopeeRnBundleVersion(): number {
    const shopeeRnBundleVersion = window.navigator.userAgent.match(
      /shopee_rn_bundle_version=(\d+)/,
    );
    if (shopeeRnBundleVersion && shopeeRnBundleVersion[1]) {
      return parseInt(shopeeRnBundleVersion[1], 10);
    }
    return 0;
  }

  /**
   * 获取app_type, UNKNOWN = 0 Shopee = 1 FoodyDriver = 4 ShopeePay = 5 ShopeeLite = 6 Airpay = 9
   * @returns number
   */
  getAppType(): number {
    const appType = window.navigator.userAgent.match(/app_type=(\d+)/);
    if (appType && appType[1]) {
      return parseInt(appType[1], 10);
    }
    return 0;
  }

  /**
   * 获取操作系统的版本
   * @returns string
   */
  getSystemVersion(): string {
    const pattern = /\((.+?)\)/;
    const agentInfo = pattern.exec(navigator.userAgent);

    if (agentInfo && agentInfo.length > 1) {
      const agentInfoList = agentInfo[1].split(';') as any;

      if (this.isIOS()) {
        return agentInfoList[1]
          .match(/\d+_\d+/)[0]
          .split('_')
          .join('.');
      }

      if (this.isAndroid()) {
        return agentInfoList[1].trim().split(' ')[1];
      }
    }

    return '';
  }

  /**
   * 获取url中的参数
   * @param url string
   * @returns Record<string, any>
   */
  queryParams(url?: string) {
    const str = url || window.location.href;
    if (str.indexOf('?') < 0) {
      return {};
    }
    const query = str.substring(str.indexOf('?') + 1, str.length);
    return qs.parse(query) as any;
  }

  log(...args: any[]) {
    if (REACT_APP_ENV === 'live') {
      return;
    }

    console.log(...args);
  }
}

export default new BaseUtils();
