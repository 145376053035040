import { loading } from '@credit/tips';
import { history } from 'umi';
import config from '@/config';
import Time, { formatType } from '@/utils/time';
import { stores } from '@/stores/useStore';
import { BIZ_TYPE } from '@/stores/userInfo/userInfo.interface';
import bridge from './bridge';
import Currency from './currency';
import { BaseUtils } from './base';

export class CommonUtils extends BaseUtils {
  readonly formatDate = Time.format;

  readonly showFullLoading = loading.showFullLoading;
  readonly hideFullLoading = loading.hideFullLoading;
  readonly setFullLoadingOnly = loading.setFullLoadingOnly;
  readonly hideAllFullLoading = loading.hideAllFullLoading;

  readonly BizCommon = {
    biz_type: BIZ_TYPE.FastEscrow,
    biz_country: BIZ_COUNTRY,
  };

  readonly toThousands = (
    money: number | string,
    opts: {
      showSymbol?: boolean; // 是否展示国家的货币符合，默认不展示
      showDecimal?: boolean; // 设置成 false 的时候不展示小数部分，不会四舍五入
      precision?: number; // 设置成 0，不展示小数部分，自动四舍五入
      separator?: string; // 整数的分隔符
    } = {},
  ): string => {
    return Currency.format(Number(money), opts);
  };

  readonly goBack = () => {
    history.goBack();
  };

  // 跳转到home页
  readonly goHome = () => {
    history.push('/home');
  };

  readonly getAppState = (name: string): any => {
    const nameList = name.split('.');
    let result = stores;
    nameList.forEach((key) => {
      if (result) {
        // @ts-ignore
        result = result?.[key];
      }
    });
    return result;
  };

  readonly isFinanceCircleRnAvailable = () => {
    const appVer = Number(this.getAppVersion()).toString().slice(0, 2);
    return Number(appVer) >= 27;
  };

  readonly goToFinanceEntry = () => {
    // 印尼上线了RN版本的finance circle 且 H5版本finance Entry在cashloan产品下。
    if (BIZ_COUNTRY === 'id') {
      if (this.isFinanceCircleRnAvailable()) {
        bridge.navigateAppPath({
          path: 'rn/@shopee-rn/finance-circle/HOME',
          popSelf: true,
        });
      } else {
        bridge.navigate({
          url: `${config.cashloanDomain}/entry`,
          navbar: {
            isTransparent: 0,
            navbarStyle: 0,
            title: '',
          },
          popSelf: 1,
        });
      }
    } else {
      bridge.navigate({
        url: `${config.financeDomain}`,
        navbar: {
          isTransparent: 0,
          navbarStyle: 0,
          title: '',
        },
        popSelf: 1,
      });
    }
  };

  readonly goToSellerWallet = () => {
    bridge.navigateAppRL({
      apprl: '/rn/@shopee-rn/seller-payment/WALLET',
      params: {
        // selfDismiss: true,
      },
    });
  };

  readonly isValuableArray = (list: any) => {
    return Array.isArray(list) && list.length > 0;
  };

  readonly disabledNavBar = (visible: boolean) => {
    bridge.configurePage({
      config: {
        disableSwipeBack: visible ? 1 : 0,
        disableReload: 1,
      },
    });
    // @ts-ignore
    bridge.callHandler('dimNavbar', {
      isDim: !!visible,
      color: '000000',
      alpha: 0.41,
    });
  };

  readonly calcServiceRate = (value: number) => {
    if (isNaN(+value)) return '';
    const tempValue = Math.floor(+value * 1e3) / 10;
    return tempValue.toFixed(1);
  };

  readonly createArray = (value: any, count: number = 1) => {
    return new Array(count).fill(value);
  };

  readonly padRight = (str: string, len: number, pad: string): string => {
    if (str.length >= len) {
      return str;
    }

    const diff = len - str.length;
    const letters = this.createArray(pad, diff);

    return str + letters.join('');
  };

  readonly isNumber = (v: any) => {
    return typeof v === 'number' && !Number.isNaN(v) && Number.isFinite(v);
  };

  readonly atLest2Decimals = (value: string) => {
    if (this.isNumber(Number(value))) {
      const str = value?.toString();
      const subStrArr = str.split('.');
      const decimalStr = subStrArr[1] ?? '';
      if (decimalStr.length < 2) {
        subStrArr[1] = this.padRight(decimalStr, 2, '0');
      }
      return subStrArr.join('.');
    }
    return value;
  };

  readonly goBackOrClose = () => {
    history.goBack();
    const lastHref = window.location.href;
    setTimeout(() => {
      if (lastHref === window.location.href) {
        bridge.closeWebview();
      }
    }, 300);
  };

  readonly goEntry = () => {
    bridge.closeWebview();
  };

  readonly getDueDate = (timestamp: number | Date, showTime = false) => {
    const dateStr = formatType.format4;
    const timeStr = `${dateStr} hh:mm`;
    return Time.format(timestamp, showTime ? timeStr : dateStr);
  };
}

export default new CommonUtils();
