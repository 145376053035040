export enum PDPA_STATUS {
  NONE = 0,
  AGREED = 1,
  REJECT = 2,
  NOT_REVIEWED = 3,
  CHANGED = 4,
}

export enum PDPA_TYPE {
  NONE,
  MARKETING,
  DATA_SHARING,
  POLICY,
}

export enum COMMIT_AGREEMENT_TYPE {
  NONE = 0,
  MARKET_ACTIVATEREED = 1,
  SEA_MONEY_DATE_SHARE = 2,
  POLICY = 3,
}

export enum PDPA_ACTION {
  AGREE = 1,
  REJECT = 2,
}

export enum CHARGE_MODE {
  interest = 1,
  upfront = 2,
}

export enum ExceptionCode {
  MaintenanceException = 503,
}
