import IDEn from './id/en.json';
import MYEn from './my/en.json';
import BREn from './br/en.json';
import PHEn from './ph/en.json';
import THEn from './th/en.json';

export type EnKeys =
  | keyof typeof MYEn
  | keyof typeof IDEn
  | keyof typeof BREn
  | keyof typeof PHEn
  | keyof typeof THEn;

export type EnType = {
  [k in EnKeys]: string;
};

// eslint-disable-next-line import/no-dynamic-require
const en: EnType = require(`./${BIZ_COUNTRY}/en.json`);
export default en;
