import dayjs from '@/utils/dayjs';

interface IRegionDate {
  minDate: Date;
  maxDate: Date;

  defaultEndDate: Date;
  defaultStartDate: Date;
}

const REGION_DATE: Omit<IRegionDate, 'minDate' | 'defaultStartDate'> = {
  maxDate: dayjs().startOf('date').toDate(),
  defaultEndDate: dayjs().startOf('date').toDate(),
};

const REGION_GROUP: any = {
  MY: {
    ...REGION_DATE,
    minDate: dayjs().startOf('date').set('date', 1).set('month', 0).set('year', 2020).toDate(),
    defaultStartDate: dayjs().startOf('date').add(-6, 'day').toDate(),
  },
  BR: {
    ...REGION_DATE,
    minDate: dayjs().startOf('date').set('date', 1).set('month', 0).set('year', 2021).toDate(),
    defaultStartDate: dayjs().startOf('date').add(-7, 'day').toDate(),
  },
  PH: {
    ...REGION_DATE,
    minDate: dayjs().startOf('date').set('date', 1).set('month', 0).set('year', 2021).toDate(),
    defaultStartDate: dayjs().startOf('date').add(-6, 'day').toDate(),
  },
  TH: {
    ...REGION_DATE,
    minDate: dayjs().startOf('date').set('date', 1).set('month', 0).set('year', 2022).toDate(),
    defaultStartDate: dayjs().startOf('date').add(-6, 'day').toDate(),
  },
};

const CURRENT_DATE: IRegionDate = REGION_GROUP[(BIZ_COUNTRY || 'MY').toUpperCase()];

export const minDate = CURRENT_DATE?.minDate;
export const maxDate = CURRENT_DATE?.maxDate;
export const defaultEndDate = CURRENT_DATE?.defaultEndDate;
export const defaultStartDate = CURRENT_DATE?.defaultStartDate;
