import baseUtils from './base';
import bridge from './bridge';
import insight from './insight';
import appInfo from '@/stores/appInfo';
import common from './common';
import { PerfEventType, CodeType, ExitState } from '@/stores/userInfo/userInfo.interface';
// import * as Sentry from '@sentry/browser';

const genLoginUrl = (redirectUrl: string) => {
  let env = REACT_APP_ENV;
  env = ['uat', 'live'].indexOf(env) >= 0 ? env : 'test';
  // URL对象会自动处理编码
  const url = new URL(
    `https://${env !== 'live' ? `${env}.` : ''}${BRIDGE_HOST}/authenticate/oauth`,
  );
  // 设置使用场景为shopee app 的 webview
  url?.searchParams?.append('__mobile__', '1');
  // 设置使用场景为shopee app 的 version, 2 is ready for integration in Shopee App Webview when the user is not logged in
  url?.searchParams?.append('version', '2');
  // state 是自己定义的一个参数
  url?.searchParams?.append('state', 'fastescrow');
  // 设置client_id参数, credit 团队给的client_id为4
  url?.searchParams?.append('client_id', '4');
  // 设置redirectUrl参数
  url?.searchParams?.append('redirect_uri', redirectUrl);
  return url?.href;
};

export const goWsaUrl = () => {
  let url = window.location.href;
  if (url.indexOf('?') > -1) {
    url = url.substring(0, url.indexOf('?'));
  }
  const offlineQuery = window._offline_build_url_query;
  // 兼容离线包
  if (offlineQuery) {
    url = `${url}index.html?${offlineQuery}`;
  }
  // 回跳的url形如 'https://cashloan.test.shopeekredit.co.id/activate?code=xxx&&errcode=0&errmsg=OK';
  const loginUrl = genLoginUrl(url);
  // console.log(loginUrl);

  insight.loginReport({
    status: 'call',
    type: 'nonce_code',
  });

  window.location.href = loginUrl;
};

// 延迟
const delay = (time: number) => new Promise((resolve) => setTimeout(resolve, time));

const requestAuthCodeFromCoreAuth = async (params?: { clientID?: string }) => {
  if (baseUtils.isPC()) {
    return '';
  }
  // @ts-ignore
  const res: any = await bridge.call('requestAuthCodeFromCoreAuth', {
    clientID: params?.clientID || 'shopee-kredit',
  });

  insight.bridgeReport({
    name: 'requestAuthCodeFromCoreAuth',
    status: res?.errorCode === 0 ? 'success' : 'error',
    msg: `${res?.error}`,
  });

  if (res?.errorCode === 0) {
    return res.authCode;
  }
  return '';
};

/**
 * 尝试获取authCode，按time进行重试的次数。
 * @param time
 */
const getAuthCodeByRetryTimes = async (time: number) => {
  let retryTime: number = time <= 0 ? 1 : time;

  while (retryTime > 0) {
    --retryTime;
    // eslint-disable-next-line no-await-in-loop
    const authCode = await requestAuthCodeFromCoreAuth();
    if (authCode) {
      return authCode;
    }

    // 加200毫秒的延迟
    // eslint-disable-next-line no-await-in-loop
    await delay(200);
  }

  return '';
};

/**
 * 获取auth code
 */
export async function getAuthCode(): Promise<{
  errorCode: number;
  token: string;
  type: CodeType;
}> {
  const urlParams = baseUtils.queryParams();
  let nonceCode: string = urlParams.nonce;
  if (appInfo.nonceCode === nonceCode) {
    // url带了noncecode参数，只能用一次。
    nonceCode = '';
  }

  if (nonceCode) {
    appInfo.nonceCode = nonceCode;
    return {
      errorCode: 0,
      token: nonceCode,
      type: CodeType.OnceCode,
    };
  }

  if (urlParams?.state === 'fastescrow' && urlParams?.exit_state !== ExitState.Success) {
    // get wsa code fail。1告诉调用方，不用再重试，避免死循环
    // @ts-ignore
    bridge.callHandler('trackPerformanceEvent', {
      subtype: PerfEventType.getAuthCodeFail,
      payload: {
        fail_type: 1,
        err_code: urlParams?.exit_state,
      },
    });

    return {
      errorCode: 1,
      token: '',
      type: CodeType.OnceCode,
    };
  }

  // mock环境对authcode无要求，dev环境在PC模拟器上也没有要求
  if (REACT_APP_ENV === 'mock') {
    // return [0, 'mock'];
    return {
      errorCode: 0,
      token: 'mock',
      type: CodeType.AuthCode,
    };
  }

  insight.loginReport({
    status: 'call',
    type: 'auth_code',
  });

  // 前两步处理从wsa网站重定向回来；下面的代码处理一进来的情况
  const authCode = await getAuthCodeByRetryTimes(3);

  // if (!authCode) {
  //   Sentry.captureMessage('BRIDGE_AUTHCODE_ERR', {
  //     extra: {
  //       url: window.location.href,
  //       user: common.getAppState('userInfo.userInfo') ?? {},
  //     },
  //   });
  // }

  // 如果为''，0告诉调用方，要用wsa url兜底
  return {
    errorCode: 0,
    token: authCode,
    type: CodeType.AuthCode,
  };
}
