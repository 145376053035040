import { Expose } from 'class-transformer';
import {
  IS_STOCK_USER,
  KYC_STATUS,
  FRAUD_STATUS,
  OVERDUE_STATUS,
  IS_FIRST_IN,
  RISK_REJECT_CODE,
  BIZ_TYPE,
  LOCK_TYPE,
  UC_USER_STATUS,
  UC_SCREEN_STATUS,
} from './userInfo.interface';

export class UserInfoModel {
  @Expose()
  userId?: string;

  @Expose()
  userStatus!: UC_USER_STATUS;

  @Expose()
  lockInfoList!: { biz_type: BIZ_TYPE; lock_type: LOCK_TYPE }[];

  @Expose()
  isStockUser!: IS_STOCK_USER;

  @Expose()
  screenStatus!: UC_SCREEN_STATUS;

  @Expose()
  kycStatus!: KYC_STATUS;

  @Expose()
  fraudStatus!: FRAUD_STATUS;

  @Expose()
  overdueStatus!: OVERDUE_STATUS;

  // @Expose()
  // toggleStatus: ITypes.UC_TOGGLE_STATUS

  @Expose()
  totalLimit!: number;

  @Expose()
  availableLimit!: number;

  @Expose()
  isFirstIn!: IS_FIRST_IN;

  @Expose()
  riskRejectCode!: RISK_REJECT_CODE;

  @Expose()
  coolDownEndTime!: number;

  @Expose()
  islogin!: boolean;

  @Expose()
  isFrozen!: boolean;

  @Expose()
  isKYCFrozen!: boolean;

  @Expose()
  isFraudFrozen!: boolean;

  @Expose()
  isOverdueFrozen!: boolean;

  @Expose()
  isSellerFrozen!: boolean;

  @Expose()
  isCNPJFrozen!: boolean;

  @Expose()
  isICFrozen!: boolean;

  @Expose()
  isFSKYCFrozen!: boolean;

  @Expose()
  isUnactivated!: boolean;

  @Expose()
  isUnderFrozen!: boolean;

  @Expose()
  isAccountClosureFrozen!: boolean;

  @Expose()
  isCollectionFrozen!: boolean;

  @Expose()
  isShopeeStatusFrozen!: boolean;

  @Expose()
  isSPPUnbindFrozen!: boolean;

  @Expose()
  isDPD60Frozen!: boolean;

  @Expose()
  isPaymentRejectCoolDownFrozen!: boolean;

  // @Expose()
  // needUpdateInfo!: boolean
}
