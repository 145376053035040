import insightTracker from '@finance/perfsdk';
import LevelConfig from '@credit/level-sdk';
import { localeInfo } from 'umi';
import { MDAP } from '@/utils/mdap';
import { stores } from '@/stores/useStore';
import config from '@/config';
import { metricConfigs } from './utils/v2/insight';

try {
  // 启动Mdap监控
  // dev mock 环境数据不上报
  // 非live环境，mdap的env配置统一为test，上报地址 mdap.exp
  if (!['dev', 'stable', 'mock'].includes(REACT_APP_ENV)) {
    MDAP.init(REACT_APP_ENV !== 'live' ? 'test' : 'live', BIZ_COUNTRY as any);
  }
} catch (e) {
  console.error('init MDAP error', e);
}

// 进行 insight 上报
try {
  insightTracker.init({
    appId: config.appId,
    region: BIZ_COUNTRY,
    bizType: 'fastescrow',
    isLive: REACT_APP_ENV === 'live',
    getUser: () => stores?.userInfo?.userInfo?.userId ?? '',
    xhr: (params: Record<string, any>) => {
      console.log('insight', params);
      stores?.userInfo?.requestInsight(params);
    },
    ignore: {
      ignoreApis: ['fe_chat', 'web-performance', 'web-custom'],
    },
    metricConfigs,
  });
  console.log('start init insight tracker');
} catch (error) {
  console.error('init insight tracker error', error);
}

export function onRouteChange({ matchedRoutes, location }: any) {
  if (!['dev', 'stable', 'mock'].includes(REACT_APP_ENV)) {
    let url = location?.pathname;
    if (/\/billing\/repay-result/.test(url)) {
      url = '/billing/repay-result';
    }
    MDAP.customPVReport({
      shopee_user_id: stores?.userInfo?.userInfo?.userId ?? '',
      fast_escrow_user_id: stores?.home?.home?.fast_escrow_user_id ?? '',
      url,
    });
  }

  if (!Array.isArray(matchedRoutes) || !matchedRoutes.length) {
    return false;
  }
}

// 确保在页面渲染之前，先拉取 cdn 的配置
export function render(oldRender: any) {
  if (['ph', 'th'].includes(BIZ_COUNTRY)) {
    // 如果是dev环境则转为test环境下的配置
    const envName = ['dev', 'stable', 'mock', 'test1', 'uat'].includes(REACT_APP_ENV)
      ? 'test'
      : REACT_APP_ENV;

    new LevelConfig({
      resources: {
        jump: `https://cdn.scredit.sg/${BIZ_COUNTRY}/jump/__level__/${envName}/urls.json`,
      },
      localeInfo,
    })
      .init()
      .catch(() => {
        // Sentry.captureMessage('PullLevelCDNError'); // sentry 上报
      })
      .finally(() => {
        oldRender();
      });
  } else {
    oldRender();
  }
}
