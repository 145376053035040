namespace Order {
  export enum API {
    'get_order_list' = '/fast-escrow/order/get_settle_list',
    'get_order_detail' = '/fast-escrow/order/get_settle_detail',
  }

  export enum RefactorAPI {
    'get_order_list' = '/fast-escrow/transaction/query_settle_list',
    'get_order_detail' = '/fast-escrow/transaction/query_settle_detail',
  }
}

export default Order;
