import { makeObservable, override } from 'mobx';
import { CommonService } from './common';

export class TransactionService extends CommonService {
  constructor() {
    super();
    makeObservable(this);
  }

  @override
  async tabChange(activeKey: any) {
    this.tab = activeKey;
  }
}
