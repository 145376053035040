import { Currency } from '@credit/utils';
// import ICurrencyOptions from '@credit/utils/lib/utils/currency'

Currency.setRegion(BIZ_COUNTRY);
Currency.setConfig({
  showSymbol: true,
});

// 重写format方法 使Currency.getConfig起作用。原方法中实际仅几个config起了作用还因为其他项目在用不能直接改。
const format = Currency.format;
switch (BIZ_COUNTRY) {
  case 'id':
    // 不做处理。
    break;
  case 'br':
  case 'ph':
    Currency.format = (value: number, options?: any): string => {
      return format.call(Currency, value, {
        precision: 2,
        ...options,
      });
    };
    break;
  case 'th':
    Currency.format = (value: number, options?: any): string => {
      return format.call(Currency, value, {
        precision: 0,
        maxPrecision: 0,
        ...options,
      });
    };
    break;
  default:
    Currency.format = (value: number, options?: any): string => {
      return format.call(Currency, value, {
        ...Currency.getConfig(),
        ...options,
      });
    };
    break;
}

export const symbol = Currency.getSymbol();

export default Currency;
