import React, { ReactNode } from 'react';
import { Toast } from 'antd-mobile';
import SuccessfullySVG from '@/assets/v5/successfully.svg';
import FailedSVG from '@/assets/v5/failed.svg';

const newToast: typeof Toast & { fail: Function; success: Function } = Object.assign({}, Toast, {
  fail: (content?: string | ReactNode, duration?: number) => {
    return Toast.show({
      content,
      maskClickable: false,
      duration: duration === undefined || duration === null ? duration : duration * 1000,
      icon: (
        <img
          src={FailedSVG}
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      ),
    });
  },
  success: (content?: string | ReactNode, duration?: number) => {
    return Toast.show({
      content,
      maskClickable: false,
      duration: duration === undefined || duration === null ? duration : duration * 1000,
      icon: (
        <img
          src={SuccessfullySVG}
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      ),
    });
  },
});

export default newToast;
