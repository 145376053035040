import { makeObservable, action, observable } from 'mobx';
import { BaseService } from '../base';
import { OrderModel } from '@/stores/order/order.model';
import { ORDER_STATUS } from '@/stores/order/order.interface';
import { hideAllFullLoading, getAppState } from '@/utils/common';

export class CommonService extends BaseService {
  @observable tab: string = 'advance';

  @observable from: string = '';

  constructor() {
    super();
    makeObservable(this);
  }

  @action
  async initTab(params: { tab?: string; status?: string; from?: string }) {
    const { tab = 'advance', status = '0', from = '' } = params;
    const pathHistory = getAppState('general.pathHistory') ?? [];
    const notClearPath = ['/advance/info', '/order/info', '/e-invoice'];
    const order = getAppState('order') ?? {};
    const advance = getAppState('advance') ?? {};

    // 若是回退操作则保留原来的筛选条件
    if (pathHistory?.length >= 2 && notClearPath.includes(pathHistory[pathHistory.length - 2])) {
      order?.initOrderListButMaintainFilters();
      advance?.initAdvanceListButMaintainFilters();
    } else {
      this.tab = tab;
      this.from = from;
      order?.initOrderList();
      advance?.initAdvanceList();
      if (tab === 'order') {
        order?.updateStore({
          status: Number(status) as ORDER_STATUS,
        } as OrderModel);
      }
    }
    Promise.all([order?.getOrderList(), advance?.getAdvanceList()]).finally(hideAllFullLoading);
  }

  @action
  async tabChange(tab: { key: string; title: string }, index: number) {
    this.tab = tab.key;
  }
}
