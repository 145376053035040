import { makeObservable, observable, override } from 'mobx';
import { CommonService } from './common';
import Advance from './api.interface';
import Toast from '@/components/toast';
import dayjs from '@/utils/dayjs';
import { formatMessage } from '@/utils/locale';
import { AdvanceModel } from './advance.model';
import { IMonthlyEInvoice } from './advance.interface';

export class AdvanceService extends CommonService {
  constructor() {
    super();
    makeObservable(this);
  }

  @observable monthlyInvoiceList: IMonthlyEInvoice[] = [];

  @override
  async initAdvanceList() {
    this.advanceList = new AdvanceModel();
    this.advanceList.start_time = dayjs().startOf('month').toDate();
    this.advanceList.end_time = dayjs().endOf('month').toDate();
  }

  @override
  async getAdvanceList() {
    const {
      page_size,
      start_time,
      end_time,

      loading,
    } = this.advanceList;
    if (loading) return;

    if (
      start_time &&
      end_time &&
      dayjs(start_time.getTime()).add(7, 'month').set('date', 0).valueOf() < end_time.getTime()
    ) {
      Toast.fail(formatMessage({ id: 'transaction.over_time_range' }));
      this.advanceList = {
        ...this.advanceList,
        list: [],
        dataSource: [],
      };
      return;
    }

    this.advanceList = {
      ...this.advanceList,
      loading: true,
    };

    const params = {
      start_time: start_time ? dayjs(start_time).startOf('date').unix() : null,
      end_time: end_time ? dayjs(end_time).endOf('date').unix() : null,
      pagination: {
        cursor: '0',
        page_index: 1,
        page_size,
      },
    };
    const { code, result } = await this.post(Advance.RefactorAPI.get_advance_list, {
      body: {
        ...params,
      },
    });
    if (code === 0) {
      const {
        list,
        actual_disburse_amount,
        page_info: { total, has_more, page_size, page_index },
      } = result;
      const newList = list ?? [];
      this.advanceList = {
        ...this.advanceList,
        list: newList,
        dataSource: newList,
        has_more: has_more ?? false,
        total: total ?? 0,
        actual_disburse_amount: actual_disburse_amount ?? 0,
        page_index: page_index ?? this.advanceList.page_index,
        page_size: page_size ?? this.advanceList.page_size,
        loading: false,
      };
    } else {
      this.advanceList = {
        ...this.advanceList,
        loading: false,
      };
    }
  }

  @override
  async loadMoreAdvanceList() {
    const {
      list,
      actual_disburse_amount: originTotalDisbursementAmount,

      page_index,
      page_size,
      start_time,
      end_time,

      loading,
      has_more,
    } = this.advanceList;
    if (loading || !has_more) return;

    this.advanceList = {
      ...this.advanceList,
      loading: true,
    };

    const params = {
      start_time: start_time ? dayjs(start_time).startOf('date').unix() : null,
      end_time: end_time ? dayjs(end_time).endOf('date').unix() : null,
      pagination: {
        cursor: list[list?.length - 1]?.group_id ?? '0',
        page_index: page_index + 1,
        page_size,
      },
    };
    const { code, result } = await this.post(Advance.RefactorAPI.get_advance_list, {
      body: {
        ...params,
      },
    });
    if (code === 0) {
      const { list: originList } = this.advanceList;
      const {
        list,
        actual_disburse_amount,
        page_info: { total, has_more, page_size, page_index },
      } = result;
      const newList = originList.concat(list ?? []);
      this.advanceList = {
        ...this.advanceList,
        list: newList,
        dataSource: newList,
        has_more: has_more ?? false,
        total: total ?? 0,
        actual_disburse_amount:
          page_index > 1 ? originTotalDisbursementAmount : actual_disburse_amount ?? 0,
        page_index: page_index ?? this.advanceList.page_index,
        page_size: page_size ?? this.advanceList.page_size,
        loading: false,
      };
    } else {
      this.advanceList = {
        ...this.advanceList,
        loading: false,
      };
    }
  }

  @override
  async getAdvanceInfo(group_id: string) {
    const { code, result } = await this.post(Advance.RefactorAPI.get_advance_detail, {
      body: { group_id },
    });
    if (code === 0) {
      this.advanceInfo = { ...this.advanceInfo, ...result };
    }
    return result || this.advanceInfo;
  }

  @override
  async getAdvanceOrderList(group_id: string) {
    const { page_info, list } = this.infoOrderList;
    if (!page_info.has_more || !this.orderLoaded) return;
    this.orderLoaded = false;

    const { code, result } = await this.post(Advance.RefactorAPI.get_group_orders, {
      body: {
        group_id,
        pagination: {
          page_index: page_info.page_index + 1,
          page_size: page_info.page_size,
          // 除了第一次传'0'，这里跟dingyuan对的时候发现后端返回的id一直是空字符串，所以我们传递的cursor也一直是空字符串，后端没有用，可以不传
          // cursor: list.length > 0 ? list?.[list.length - 1]?.id ?? '0' : '0',
        },
      },
    });
    if (code === 0) {
      const newList = [...this.infoOrderList.list, ...(result.list ?? [])];
      this.infoOrderList = {
        ...this.infoOrderList,
        ...result,
        list: newList,
      };
      this.orderLoaded = true;
    }
    this.orderLoaded = true;
    return result;
  }

  async getUserInvoicesByMonth({ year, month }: { year: number; month: number }) {
    const { code, result } = await this.post(Advance.RefactorAPI.get_user_invoices_by_month, {
      body: {
        year,
        month,
      },
    });
    if (code === 0) {
      const { user_invoice_list = [] } = result;
      this.monthlyInvoiceList = user_invoice_list ? [...user_invoice_list] : [];
    }
  }
}
